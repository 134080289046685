type ReducerState = {
  value: File | null;
  isValid: boolean | null;
};

type Input = { type: 'INPUT'; val: File | null };
type Blur = { type: 'BLUR' };
type Reset = { type: 'RESET' };

type ReducerActions = Input | Blur | Reset;

export function notEmptyFileReducer(
  state: ReducerState,
  action: ReducerActions
): ReducerState {
  if (action.type === 'INPUT') {
    return {
      value: action.val,
      isValid: !!action.val,
    };
  }
  if (action.type === 'BLUR') {
    return {
      value: state.value,
      isValid: !!state.value,
    };
  }
  if (action.type === 'RESET') {
    return notEmptyFileInitialState;
  }
  return { value: null, isValid: false };
}

export const notEmptyFileInitialState = {
  value: null,
  isValid: null,
};
