import { Link, NavLink, useLocation } from 'react-router-dom';
import { motion, useScroll, useTransform } from 'framer-motion';
import logo from '../assets/images/logo-full.png';
import menuIcon from '../assets/icons/menu.svg';

import classes from './NavBar.module.scss';
import sizes from '../style/sizes.module.scss';
import MobileDrawer from './MobileDrawer';
import { useEffect, useState } from 'react';

const regex = /\/(assignments|roles)\/[^/]+/;

function NavBarDesktop() {
  const { scrollY } = useScroll();
  const vh = window.innerHeight;

  const animationStart = vh / 100;
  const animationEnd = vh / 100 + 150;

  const { pathname } = useLocation();

  const [isAssignmentPage, setIsAssignmentPage] = useState(false);
  useEffect(() => {
    setIsAssignmentPage(!!pathname && regex.test(pathname));
  }, [pathname]);

  const navBarHeight = useTransform(
    scrollY,
    [animationStart, animationEnd],
    [100, 75]
  );
  const navBarBackground = useTransform(
    scrollY,
    [animationStart, animationEnd],
    [isAssignmentPage ? '#302e2eff' : '#302e2e00', '#302e2eff']
  );
  const navBarBorder = useTransform(
    scrollY,
    [animationStart, animationEnd],
    ['#ffffffff', '#ffffff00']
  );

  const navPaddingX = useTransform(
    scrollY,
    [animationStart, animationEnd],
    [16, 0]
  );

  const logoHeight = useTransform(
    scrollY,
    [animationStart, animationEnd],
    [sizes.logoHeightMedium, sizes.logoHeightSmall]
  );
  const fontSize = useTransform(
    scrollY,
    [animationStart, animationEnd],
    ['18px', '16px']
  );

  return (
    <motion.div
      style={{
        height: navBarHeight,
        backgroundColor: navBarBackground,
        borderColor: navBarBorder,
      }}
      className={`${classes.navbar}`}
    >
      <div className={`${classes['navbar-content']} row`}>
        <Link to='/' className='d-none col-md'>
          <motion.img
            style={{ height: logoHeight }}
            src={logo}
            alt='Iceberry logo'
          />
        </Link>
        <div className='d-none col-md'>
          <motion.nav
            style={{ borderColor: navBarBorder, paddingRight: navPaddingX }}
          >
            <NavLink
              to='/about'
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              <motion.div style={{ fontSize: fontSize }}>Vårt DNA</motion.div>
            </NavLink>
            <NavLink
              to='/career'
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              <motion.div style={{ fontSize: fontSize }}>
                Jobba hos oss
              </motion.div>
            </NavLink>
            <NavLink
              to='/assignments'
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              <motion.div style={{ fontSize: fontSize }}>Uppdrag</motion.div>
            </NavLink>
            <NavLink
              to='/courses'
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              <motion.div style={{ fontSize: fontSize }}>
                Våra kurser
              </motion.div>
            </NavLink>
            <NavLink
              to='/contact'
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              <motion.div style={{ fontSize: fontSize }}>
                Kontakta oss
              </motion.div>
            </NavLink>
          </motion.nav>
        </div>
      </div>
    </motion.div>
  );
}

function NavBarMobile() {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const { scrollY } = useScroll();
  const vh = window.innerHeight;

  const { pathname } = useLocation();
  const [isAssignmentPage, setIsAssignmentPage] = useState(false);

  useEffect(() => {
    setIsAssignmentPage(!!pathname && regex.test(pathname));
  }, [pathname]);

  const animationStart = vh / 100;
  const animationEnd = vh / 100 + (vh / 100) * 10;

  const navBarBackground = useTransform(
    scrollY,
    [animationStart, animationEnd],
    [isAssignmentPage ? '#302e2eff' : '#302e2e00', '#302e2eff']
  );
  const navBarBorder = useTransform(
    scrollY,
    [animationStart, animationEnd],
    ['#ffffffff', '#ffffff00']
  );

  return (
    <motion.div
      style={{
        height: 75,
        backgroundColor: navBarBackground,
        borderColor: navBarBorder,
      }}
      className={`${classes.navbar}`}
    >
      <div className={`${classes['navbar-content']} row`}>
        <Link to='/' className='col'>
          <img
            className={classes['mobile-img']}
            src={logo}
            alt='Iceberry logo'
          />
        </Link>
        <div style={{ color: 'white' }} className='col'>
          <img
            src={menuIcon}
            className='icon white'
            alt='Menu icon'
            onClick={() => setDrawerIsOpen(prevState => !prevState)}
          />
        </div>
      </div>
      <MobileDrawer
        isOpen={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
      >
        <nav className={`${classes['mobile-nav']} large-text`}>
          <NavLink
            onClick={() => setDrawerIsOpen(false)}
            to='/'
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Hem
          </NavLink>
          <NavLink
            onClick={() => setDrawerIsOpen(false)}
            to='/about'
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Vårt DNA
          </NavLink>
          <NavLink
            onClick={() => setDrawerIsOpen(false)}
            to='/career'
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Jobba hos oss
          </NavLink>
          <NavLink
            onClick={() => setDrawerIsOpen(false)}
            to='/assignments'
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Uppdrag
          </NavLink>
          <NavLink
            onClick={() => setDrawerIsOpen(false)}
            to='/courses'
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Våra kurser
          </NavLink>
          <NavLink
            onClick={() => setDrawerIsOpen(false)}
            to='/contact'
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            Kontakta oss
          </NavLink>
        </nav>
      </MobileDrawer>
    </motion.div>
  );
}

export default function NavBar() {
  return (
    <>
      <div className='d-block d-md-none'>
        <NavBarMobile />
      </div>
      <div className='d-none d-md-block'>
        <NavBarDesktop />
      </div>
    </>
  );
}
