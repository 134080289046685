import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './App.scss';

import Root from './pages/Root';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import Courses from './pages/Courses';
import CareerPage from './pages/Career';
import RolesPage from './pages/Roles';
import RoleDetailsPage from './pages/RoleDetails';
import { loader as roleDetailsLoader } from './pages/RoleDetails';
import ContactPage from './pages/Contact';
import IntegrityPage from './pages/Integrity';
import AssignmentsPage from './pages/Assignments';
import UnsubscribePage from './pages/Unsubscribe';
import ShiftLeftPage from './pages/courses/ShiftLeft';
import AssignmentDetailPage from './pages/AssignmentDetail';
import AccessibilityPage from './pages/courses/Accessibility';
import PerformanceTestingPage from './pages/courses/PerformanceTesting';
import { loader as assignmentDetailLoader } from './pages/AssignmentDetail';
import LedScreenPage from './pages/LedScreenPage';

import SeoComponent from './components/HeadComponent';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: 'about',
        element: <AboutPage />,
      },
      {
        path: 'career',
        children: [
          {
            path: '',
            element: <CareerPage />,
          },
          {
            path: 'roles',
            children: [
              { element: <RolesPage />, path: '' },
              {
                element: <RoleDetailsPage />,
                path: ':id',
                loader: roleDetailsLoader,
              },
            ],
          },
        ],
      },
      {
        path: 'assignments',
        children: [
          {
            path: '',
            element: <AssignmentsPage />,
          },
          {
            path: ':id',
            element: <AssignmentDetailPage />,
            loader: assignmentDetailLoader,
          },
        ],
      },
      {
        path: 'contact',
        element: <ContactPage />,
      },
      {
        path: 'integrity',
        children: [
          {
            path: 'se',
            element: <IntegrityPage language='se' />,
          },
          {
            path: 'en',
            element: <IntegrityPage language='en' />,
          },
        ],
      },
      {
        path: 'courses',
        children: [
          {
            path: '',
            element: <Courses />,
          },
          {
            path: 'performance',
            element: <PerformanceTestingPage />,
          },
          {
            path: 'accessibility',
            element: <AccessibilityPage />,
          },
          {
            path: 'shift-left',
            element: <ShiftLeftPage />,
          },
        ],
      },
      {
        path: 'unsubscribe/:assignmentSubscriberId',
        element: <UnsubscribePage />,
      },
    ],
  },
  {
    path: 'led',
    element: <LedScreenPage />,
  },
]);

function App() {
  return (
    <HelmetProvider context={{}}>
      <SeoComponent
        description='Välkommen till Iceberry – ett IT-konsultföretag specialiserat på utveckling, test och kvalitetssäkring. Läs mer om våra tjänster och karriärmöjligheter!'
        keywords='IT-konsultföretag,Digital transformation,IT-lösningar,IT-partnerskap,Mjukvaruutveckling,Backendutveckling,Mjukvarutestning,Teknisk testning,Jobb inom IT,Karriärmöjligheter IT,IT-konsult Sverige,Digital innovation,Projektledning IT,Systemutveckling'
      />
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
