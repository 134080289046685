import vrWomanImg from '../assets/images/VR.webp';
import peopleImg from '../assets/images/Samlade_Människor3.webp';
import drawingImg from '../assets/images/Kurssida3.webp';
import handWithCircleImg from '../assets/images/Kurssida.webp';
import arrow from '../assets/icons/arrow.svg';

import classes from './Courses.module.scss';
import LinkButton from '../components/LinkButton';
import Section from '../components/Section';
import TextOverImageSection from '../components/TextOverImageSection';
import Hero from '../components/Hero';
import SeoComponent from '../components/HeadComponent';

const heroHeader =
  'Utbildning och coachning har alltid funnits i Iceberrys DNA.';
const heroText =
  'Genom åren har vi coachat och utbildat hundratals företag inom testning, kvalitetssäkring och förändringsledning.';

export default function Courses() {
  return (
    <>
      <SeoComponent
        description='Se Iceberrys öppna kurser eller kontakta oss för en skäddarsydd lösning – vi hjälper dig som vill utvecklas inom mjukvaruutveckling och testning.'
        keywords='IT-utbildningar,Kurser inom IT,Professionell utveckling IT,IT-kurser Sverige,Karriärutveckling kurser,IT-certifieringar,Kurser för utvecklare,Digital kompetensutveckling,ISTQB,ISTQB Foundation,Webbtillgänglighetsdirektivet,WCAG,Shift-Left,Prestandatestning'
      />

      <Hero
        image={vrWomanImg}
        imageAlt='A woman wearing a VR-headset'
        headerText={heroHeader}
        text={heroText}
        textPos='right'
        textColor='white'
        imageOverlay='#00000070'
      />
      <Section backgroundColor='light-varm' textColor='black'>
        <div className={`row ${classes['text-arrow-row']}`}>
          <div className={`col ${classes['text-col']}`}>
            <h5 className='mb-3'>
              Här kan du se de öppna kurser vi erbjuder just nu.
            </h5>
            <div className='text'>
              Har du behov av en skräddarsydd lösning? Kontakta oss! Baserat på
              era mål och behov kan vi hjälpa till på både ett strategiskt och
              praktiskt plan för att lyfta er kompetens genom t ex workshops,
              utbildningar och coaching.
            </div>
          </div>
          <div className='d-none col-md'>
            <img src={arrow} alt='An arrow' className={classes.arrow} />
          </div>
        </div>
      </Section>
      <TextOverImageSection
        backgroundColor='black'
        textColor='white'
        image={peopleImg}
        imageAlt='TBA'
        imagePosition='left'
        colWidth='5'
        heightDesktopPx={630}
      >
        <h4 className='mb-3'>Tillgänglighetskurs</h4>
        <div className='text mb-2'>
          Lär dig skapa inkluderande digitala upplevelser genom att förstå och
          implementera tillgänglighetsprinciper för webbplatser och appar.
        </div>
        <div className='text'>Längd: 2 dagar</div>
        <div className='text mb-3'>Plats: Stockholm, Göteborg & distans</div>
        <LinkButton
          className={classes['course-button']}
          to='accessibility'
          color='white'
        >
          Läs mer
        </LinkButton>
      </TextOverImageSection>
      <TextOverImageSection
        backgroundColor='yellow'
        textColor='black'
        image={drawingImg}
        imageAlt='TBA'
        imagePosition='right'
        colWidth='5'
        heightDesktopPx={630}
      >
        <h4 className='mb-3'>Shift-left i praktiken</h4>
        <div className='text mb-2'>
          Shift-Left. Agile. DevOps. Automatisering. Vi har hört det ett tag nu,
          men är det testarens nyckel till framtiden eller ännu en box att
          checka av?
        </div>
        <div className='text'>Längd: 2 dagar</div>
        <div className='text mb-3'>Plats: Stockholm, Göteborg & distans</div>
        <LinkButton
          className={classes['course-button']}
          to='shift-left'
          color='black'
        >
          Läs mer
        </LinkButton>
      </TextOverImageSection>
      <TextOverImageSection
        backgroundColor='black'
        textColor='white'
        image={handWithCircleImg}
        imageAlt='TBA'
        imagePosition='left'
        colWidth='5'
        heightDesktopPx={630}
      >
        <h4 className='mb-3'>Prestandatestning</h4>
        <div className='text mb-2'>
          Förstå prestandatestning grundligt, så att du kan maximera nyttan
          genom strategiska val. Teori och praktik.
        </div>
        <div className='text'>Längd: 2 dagar</div>
        <div className='text mb-3'>Plats: Stockholm, Göteborg & distans</div>
        <LinkButton
          className={classes['course-button']}
          to='performance'
          color='white'
        >
          Läs mer
        </LinkButton>
      </TextOverImageSection>
    </>
  );
}
