import breakpointsModule from './breakpoints.module.scss';

//Created because we sometimes need to use these numbers in comparations in ts-files,
//breakpoints.module.scss only exports values as strings and with 'px' suffix (for example sm = '600px')

//Exported as numbers for use in ts-files (for example: sm = 600)
const smBreakpoint = 600;
const mdBreakpoint = 768;
const lgBreakpoint = 992;
const xlBreakpoint = 1200;
const xxlBreakpoint = 1500;
const xxxlBreakpoint = 1920;

//Throws error if values in _breakpoints.scss has been changed.
//If this error is thrown, please update the variables in this file so they matches the variables in _breakpoints.scss
if (
  breakpointsModule.sm !== '600px' ||
  breakpointsModule.md !== '768px' ||
  breakpointsModule.lg !== '992px' ||
  breakpointsModule.xl !== '1200px' ||
  breakpointsModule.xxl !== '1500px' ||
  breakpointsModule.xxxl !== '1920px'
) {
  throw new Error(
    'Detected change in _breakpoints.scss, please also update variables in breakpoint-numbers.ts'
  );
}

export {
  smBreakpoint,
  mdBreakpoint,
  lgBreakpoint,
  xlBreakpoint,
  xxlBreakpoint,
  xxxlBreakpoint,
};
