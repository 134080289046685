import { useEffect, useState } from 'react';

import { Assignment } from '../models/Assignment';

import mountainImg from '../assets/images/Klättra.webp';

import classes from './Assignments.module.scss';

import Hero from '../components/Hero';
import Section from '../components/Section';
import JobButton from '../components/JobButton';
import SeoComponent from '../components/HeadComponent';
import Loading from '../components/Loading';
import AssignmentForm from '../components/AssignmentForm';
import SubscriptionForm from '../components/SubscriptionForm';

const heroHeader =
  'Är du en av våra branschkollegor som letar nytt konsultuppdrag?';
const heroText =
  'Här är de uppdrag vi har tillgängliga just nu. Du kan också registrera ditt CV hos oss, om du vill att vi ska hålla utkik efter uppdrag till dig.';

const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL ||
  'https://europe-west1-admin-publicering.cloudfunctions.net';

export default function AssignmentsPage() {
  const [assignments, setAssignments] = useState([] as Assignment[]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(BASE_API_URL + '/getActiveAssignments', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: {} }),
    })
      .then(response => response.json())
      .then(responseData => setAssignments(responseData.result.response))
      .then(() => setIsLoading(false))
      .catch(error => console.error(error));
  }, []);

  return (
    <>
      <SeoComponent
        description='Se aktuella uppdrag hos Iceberry – vi har uppdrag inom roller som utveckling, testning, UX och projektledning. Bli en del av vårt nätverk av underkonsulter.'
        keywords='IT-uppdrag,Lediga uppdrag IT,IT-konsultuppdrag,Konsultuppdrag IT,Uppdrag för utvecklare,Uppdrag för testare,Uppdrag för projektledare,Uppdrag UX,Uppdrag inom mjukvaruutveckling,Uppdrag inom teknisk testning,Uppdrag inom projektledning,Uppdrag inom UX,Uppdrag i teknikbranschen,Uppdrag för IT-konsulter,Uppdrag för frilanskonsulter IT,Uppdrag inom molnlösningar,Teknisk projektledning,Konsultnätverk IT,IT-projektledare uppdrag'
      />

      <Hero
        image={mountainImg}
        imageAlt='A lonely man climbing a mountain'
        headerText={heroHeader}
        text={heroText}
        textPos='right'
        textColor='white'
        imageOverlay='#00000060'
      />
      <Section backgroundColor='black' textColor='white'>
        <div className='row'>
          <h3 className={`${classes.header} mb-6 col-12`}>
            Våra lediga uppdrag
          </h3>
        </div>
        <div className='row text'>
          {!isLoading && assignments.length === 0 && (
            <div className='col-12'>Hittade inga lediga uppdrag.</div>
          )}
          {isLoading && (
            <div className={`${classes.list} col-12`}>
              <Loading />
            </div>
          )}
          <div className={`${classes.list} col-12`}>
            {assignments.length > 0 &&
              !isLoading &&
              assignments.map(a => (
                <JobButton
                  key={a.id}
                  linkTo={'/assignments/' + a.id}
                  smallText={a.location + ' |  Ansök senast: ' + a.deadline}
                  title={a.externalTitle}
                />
              ))}
          </div>
        </div>
      </Section>

      <AssignmentForm />

      <SubscriptionForm />
    </>
  );
}
