import classes from './CourseContentList.module.scss';

const CourseContentListDesktop: React.FC<{
  children: React.ReactNode;
  backgroundColor: 'white' | 'black' | 'yellow';
  textColor: 'white' | 'black' | 'yellow';
}> = props => {
  return (
    <div
      className={`${classes.container} ${
        classes[`b-${props.backgroundColor}`]
      } ${classes[`c-${props.textColor}`]} d-none d-md-flex`}
    >
      {props.children}
    </div>
  );
};

const CourseContentListMobile: React.FC<{
  children: React.ReactNode;
  backgroundColor: 'white' | 'black' | 'yellow';
  textColor: 'white' | 'black' | 'yellow';
}> = props => {
  return (
    <div
      className={`row d-md-none ${classes[`b-${props.backgroundColor}`]} ${
        classes[`c-${props.textColor}`]
      }`}
    >
      <div className={`col-12 ${classes['mobile-column']}`}>
        {props.children}
      </div>
    </div>
  );
};

const Content: React.FC<{
  headerText: string;
  listItems: string[];
}> = props => {
  return (
    <div className={classes.content}>
      <p className={`large-text mb-3 ${classes.header}`}>{props.headerText}</p>
      <ul className='text'>
        {props.listItems.map((i, index) => (
          <li key={index}>{i}</li>
        ))}
      </ul>
    </div>
  );
};

const CourseContentList: React.FC<{
  headerText: string;
  listItems: string[];
  backgroundColor: 'white' | 'black' | 'yellow';
  textColor: 'white' | 'black' | 'yellow';
}> = props => {
  return (
    <>
      <CourseContentListDesktop
        backgroundColor={props.backgroundColor}
        textColor={props.textColor}
      >
        <Content headerText={props.headerText} listItems={props.listItems} />
      </CourseContentListDesktop>
      <CourseContentListMobile
        backgroundColor={props.backgroundColor}
        textColor={props.textColor}
      >
        <Content headerText={props.headerText} listItems={props.listItems} />
      </CourseContentListMobile>
    </>
  );
};

export default CourseContentList;
