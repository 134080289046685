import LinkButton from '../../components/LinkButton';
import Section from '../../components/Section';
import classes from './Integrity.module.scss';

export default function EnglishIntegrity() {
  return (
    <>
      <Section backgroundColor='black' textColor='white' paddingTop='large'>
        <div className='row text'>
          <div className={`col-12 mb-6`}>
            <LinkButton color='white' className='mb-3' to={'/integrity/se'}>
              {'Du kan hitta den svenska versionen här.'}
            </LinkButton>
            <p>
              At Iceberry.se, we value your privacy and handle your personal
              data in accordance with GDPR. This policy outlines how we collect,
              use, and protect your information.
            </p>
          </div>
        </div>

        <div className='row text'>
          <div className='col-12 mb-5'>
            <h4 className='mb-1'>1. Data Controller</h4>
            <p className='mb-2'>
              Iceberry AB is responsible for your personal data. Contact us at
              <a className={classes.link} href='mailto:privacy@iceberry.se'>
                privacy@iceberry.se
              </a>{' '}
              for any questions regarding our data handling.{' '}
            </p>
          </div>
        </div>

        <div className='row text'>
          <div className='col-12 mb-5'>
            <h4 className='mb-1'>2. What data we collect</h4>
            <p>
              We only collect personal data that you actively provide when using
              our services. This may include:
            </p>
            <ul>
              <li>
                Contact Information (name, email, phone number, company, role,
                location).
              </li>
              <li>Professional Information (such as CVs and cover letters).</li>
            </ul>
          </div>
        </div>

        <div className='row text'>
          <div className='col-12 mb-5'>
            <h4 className='mb-1'>3. How we use your data</h4>
            <p className='mb-1'>We use your data to:</p>
            <ul>
              <li>Provide access to job and project searches.</li>
              <li>Recommend relevant jobs and consulting assignments.</li>
              <li>Communicate with you about the mentioned roles.</li>
            </ul>
            <p>
              We only share your data with third parties, such as potential
              clients, upon agreement, or when required by law.
            </p>
          </div>
        </div>

        <div className='row text'>
          <div className='col-12 mb-5'>
            <h4 className='mb-1'>4. Your rights</h4>
            <p className='mb-1'>You have the right to:</p>
            <ul>
              <li>Access your data.</li>
              <li>Request correction or deletion.</li>
              <li>Withdraw your consent.</li>
            </ul>
          </div>
        </div>

        <div className='row text'>
          <div className='col-12 mb-5'>
            <h4 className='mb-1'>5. Data retention</h4>
            <p>
              Your data is stored only as long as necessary to provide the
              service or for a maximum of two years.
            </p>
          </div>
        </div>

        <div className='row text'>
          <div className='col-12'>
            <h4 className='mb-1'>6. Contact</h4>
            <p>
              If you have any questions, contact us at{' '}
              <a className={classes.link} href='mailto:privacy@iceberry.se'>
                privacy@iceberry.se
              </a>
              .
            </p>
          </div>
        </div>
      </Section>
    </>
  );
}
