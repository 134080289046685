import { Link } from 'react-router-dom';
import arrowIcon from '../assets/icons/arrow.svg';

import classes from './LinkButton.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

const variants = {
  normal: {
    width: 0,
  },
  hover: {
    width: '100%',
  },
};

const LinkButton: React.FC<{
  to: string;
  children: React.ReactNode;
  color: 'black' | 'white';
  className?: string;
}> = props => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      to={props.to}
      className={`${props.className} ${classes.button} link-text ${
        props.color ? classes[`c-${props.color}`] : ''
      }`}
    >
      <motion.div
        className={classes.container}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
      >
        <img
          src={arrowIcon}
          alt='Arrow pointing at link'
          className={`icon ${props.color} ${classes.icon}`}
        />
        <div className={classes['text-container']}>
          {props.children}
          <AnimatePresence>
            {isHovered && (
              <motion.div
                className={`${classes.line} ${classes[`c-${props.color}`]}`}
                variants={variants}
                initial='normal'
                animate='hover'
                exit='normal'
              ></motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </Link>
  );
};

export default LinkButton;
