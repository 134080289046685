import classes from './ShiftLeft.module.scss';

import grassImg from '../../assets/images/Gräsmatta.webp';
import yellowArrow from '../../assets/images/yellow-arrow.webp';
import CourseContentList from '../../components/CourseContentList';
import Hero from '../../components/Hero';
import Section from '../../components/Section';
import TabHeader from '../../components/TabHeader';
import BasicCourseForm from '../../components/BasicCourseForm';

const heroText =
  'Shift Left. Agile. DevOps. Automatisering. Vi har hört det ett tag nu, men är det testarens nyckel till framtiden eller ännu en box att checka av?';

const contentListItems1 = [
  'Varför testa? Vem, vad, när, hur? Ett team många ansvar',
  'Den moderna utvecklingssetupen och dess fördelar samt jämförelse med traditionell setup',
  'DevOps: test i utvecklingsprocess, att mäta framsteg',
  'Att förhålla sig till andra team eller större sammanhang',
  'Agila buzzwords i praktiken, Shift-Right, Canary, A/B, Chaos Engineering, State of DevOps report och metrics, devtestperfsecwhatnotops?',
];

const contentListItems2 = [
  'Unit, UI, integration',
  'Icke-funktionell testning',
  'Mocking',
  'Monitorering',
  'xUnit, Jest, Selenium, Postman',
];

const contentListItems3 = [
  'Epics, user stories, buggar och test',
  'Jira-storyn - ett ärendehanteringssystem eller kravverktyg?',
];

const contentListItems4 = [
  'Är alla utvecklare nu?',
  'Får man ha renodlade testare i teamet?',
  'Gruppdynamik',
];

const contentListItems5 = [
  'CI/CD och build pipeline',
  'Testning back-end och front-end',
  'Testa ditt API: Intern eller publik leverans',
  'Ett själv-organiserande team - att leda sig själv?',
];

export default function ShiftLeftPage() {
  return (
    <>
      <Hero
        image={grassImg}
        imageAlt='A shadow of a human on some plastic grass'
        imageOverlay='#00000050'
        textPos='left'
        textColor='white'
        headerText='Shift left i praktiken'
        headerColor='yellow'
        text={heroText}
      />
      <Section
        backgroundColor='black'
        textColor='white'
        paddingBottom='large'
        rounded='top'
      >
        <div className='row'>
          <div className={`col-12 text ${classes.col}`}>
            <div className={`d-none d-lg-block ${classes.arrow}`}>
              <img src={yellowArrow} alt='A yellow arrow' />
            </div>
            <h4 className='mb-3'>Shift left i praktiken</h4>
            <p className='mb-2'>
              Att skifta vänster innebär att tidigarelägga så mycket som möjligt
              av högprioriterat arbete. Det har många fördelar, men allt kan och
              bör inte göras tidigt eller direkt. Den här kursen reder ut varför
              den klassiska testpyramiden kom till, dess för- och nackdelar, hur
              den är kopplad till fenomenet shift-left och kärnbudskapet - vad
              som egentligen är viktigt - så att du kan fokusera på rätt
              prioritering när du automatiserar.
            </p>
            <p className='mb-2'>
              Kursen riktar sig till alla som jobbar i ett agilt team, oavsett
              bakgrund, och ger även dig som ledare en bättre förståelse för vad
              som leder till framgång vare sig du erbjuder lösningar på plats
              eller i molnet.
            </p>
            <p>
              Du deltar i kursen genom att varva teoretisk kunskap med att
              kontinuerligt omsätta dessa i praktiken - en total rundvandring i
              alla delar som tillsammans bygger det praktiska arbete som kommer
              med det agila konceptet.
            </p>
          </div>
        </div>
      </Section>
      <TabHeader
        headerText='Kursen berör följande:'
        headerColor='white'
        borderColor='yellow'
      />
      <div className={`${classes.lists} ${classes.first}`}>
        <CourseContentList
          backgroundColor='yellow'
          textColor='black'
          headerText='Teststrategi i shift-left'
          listItems={contentListItems1}
        />
        <CourseContentList
          backgroundColor='yellow'
          textColor='black'
          headerText='Verktygsval'
          listItems={contentListItems2}
        />
      </div>
      <div className={classes.lists}>
        <CourseContentList
          backgroundColor='yellow'
          textColor='black'
          headerText='Krav och produktägarskap'
          listItems={contentListItems3}
        />
        <CourseContentList
          backgroundColor='yellow'
          textColor='black'
          headerText='Roller'
          listItems={contentListItems4}
        />
        <CourseContentList
          backgroundColor='yellow'
          textColor='black'
          headerText='Övrigt'
          listItems={contentListItems5}
        />
      </div>
      <BasicCourseForm courseName='Shift left i praktiken' />
    </>
  );
}
