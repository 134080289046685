type ReducerState = {
  value: string | null;
  isValid: boolean | null;
};

type Input = { type: 'INPUT'; val: string | null };
type Blur = { type: 'BLUR' };
type Reset = { type: 'RESET' };

type ReducerActions = Input | Blur | Reset;

export function notEmptyStringReducer(
  state: ReducerState,
  action: ReducerActions
): ReducerState {
  if (action.type === 'INPUT') {
    return {
      value: action.val,
      isValid: action.val ? action.val.trim().length > 0 : false,
    };
  }
  if (action.type === 'BLUR') {
    return {
      value: state.value,
      isValid: state.value ? state.value.trim().length > 0 : false,
    };
  }
  if (action.type === 'RESET') {
    return notEmptyInitialState;
  }
  return { value: '', isValid: false };
}

export const notEmptyInitialState = {
  value: null,
  isValid: null,
};
