import { Suspense, useEffect, useState } from 'react';
import { Await, defer, json, useLoaderData } from 'react-router-dom';

import { InternalRole } from '../models/InternalRole';

import pinIcon from '../assets/icons/pin.svg';
import classes from './AssignmentDetail.module.scss';

import Section from '../components/Section';
import InternalRoleForm from '../components/InternalRoleForm';

import { lgBreakpoint } from '../style/breakpoint-numbers';

const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL ||
  'https://europe-west1-admin-publicering.cloudfunctions.net';

export default function RoleDetailsPage() {
  const { internalRole } = useLoaderData() as any;
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const fallback = (
    <div className={classes.fallback}>
      <h2>Hämtar...</h2>
    </div>
  );

  const assignScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', assignScreenSize);

    return () => {
      window.removeEventListener('resize', assignScreenSize);
    };
  }, []);

  return (
    <Suspense fallback={fallback}>
      <Await resolve={internalRole}>
        {(loadedAssignment: InternalRole) => (
          <>
            <Section
              backgroundColor='yellow'
              textColor='black'
              paddingTop={screenSize > lgBreakpoint ? 'xl' : 'large'}
              paddingBottom={screenSize > lgBreakpoint ? 'large' : 'default'}
            >
              <div className='row'>
                <div className='col'>
                  <h2 className='mb-6'>{loadedAssignment.title}</h2>
                  <div className={`${classes.info} mb-3`}>
                    <div className={classes.location}>
                      <img
                        src={pinIcon}
                        alt='A map pin'
                        className='icon black'
                      />
                      <p>{loadedAssignment.location}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Section>
            <Section backgroundColor='black' textColor='white'>
              <div className='row'>
                <div className='col-12 text'>
                  <div className={`${classes.card}`}>
                    <div
                      className='mb-3'
                      dangerouslySetInnerHTML={{
                        __html: loadedAssignment.htmlDescription,
                      }}
                    ></div>
                    <div className={classes.apply}></div>
                  </div>
                  <InternalRoleForm
                    roleId={loadedAssignment.id}
                    cinodePipelineId={loadedAssignment.cinodePipelineId}
                  />
                </div>
              </div>
            </Section>
          </>
        )}
      </Await>
    </Suspense>
  );
}

export function loader({ params }: any) {
  return defer({
    internalRole: loadRole(params.id!),
  });
}

async function loadRole(id: string) {
  const response = await fetch(BASE_API_URL + '/getInternalRole', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: id }),
  });

  if (!response.ok) {
    throw json(
      { message: 'Could not fetch internal role' },
      {
        status: 500,
      }
    );
  } else {
    const resData = await response.json();

    return resData.result.data;
  }
}
