import Section from './Section';
import classes from './BasicCourseForm.module.scss';
import FormInput from './FormInput';
import { useReducer, useRef, useState } from 'react';
import { emailInitialState, emailReducer } from '../reducers/email-reducer';
import { phoneInitialState, phoneReducer } from '../reducers/phone-reducer';
import {
  notEmptyInitialState,
  notEmptyStringReducer,
} from '../reducers/not-empty-string-reducer';
import Button from './Button';
import FormGdprCheckbox from './FormGdprCheckbox';
import {
  checkboxInitialState,
  checkboxReducer,
} from '../reducers/checkbox-reducer';

const BasicCourseForm: React.FC<{
  courseName: string;
}> = props => {
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const companyRef = useRef<HTMLInputElement>(null);
  const policyRef = useRef<HTMLInputElement>(null);

  const [emailState, dispatchEmail] = useReducer(
    emailReducer,
    emailInitialState
  );

  const [nameState, dispatchName] = useReducer(
    notEmptyStringReducer,
    notEmptyInitialState
  );

  const [phoneState, dispatchPhone] = useReducer(
    phoneReducer,
    phoneInitialState
  );

  const [companyState, dispatchCompany] = useReducer(
    notEmptyStringReducer,
    notEmptyInitialState
  );

  const [acceptsPolicyState, dispatchAcceptsPolicy] = useReducer(
    checkboxReducer,
    checkboxInitialState
  );

  const [role, setRole] = useState('');
  const [city, setCity] = useState('');
  const [other, setOther] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [submitError, setSubmitError] = useState('');

  async function submitHandler(event: any) {
    event.preventDefault();
    if (!nameState.isValid) {
      nameRef.current!.focus();
      return;
    }
    if (!emailState.isValid) {
      emailRef.current!.focus();
      return;
    }
    if (!phoneState.isValid) {
      phoneRef.current!.focus();
      return;
    }
    if (!companyState.isValid) {
      companyRef.current!.focus();
      return;
    }
    if (!acceptsPolicyState.isValid) {
      policyRef.current!.focus();
      return;
    }

    const requestData = {
      courseName: props.courseName,
      name: nameState.value,
      email: emailState.value,
      phoneNumber: phoneState.value,
      company: companyState.value,
      role,
      city,
      other,
      testRecipient: 'viktor.johansson@iceberry.se',
    };

    try {
      setIsSubmitting(true);
      setSubmitError('');
      setSubmitSuccessful(false);
      const response = await fetch(
        'https://europe-west1-admin-publicering.cloudfunctions.net/submitCourseApplication',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ data: requestData }),
        }
      );

      if (!response) {
        setSubmitError('Något gick fel, kunde inte skicka in anmälan.');
        return;
      }

      const responseJson = await response.json();
      const result = responseJson.result;

      if (result.status) {
        setSubmitSuccessful(true);
        dispatchName({ type: 'RESET' });
        dispatchEmail({ type: 'RESET' });
        dispatchPhone({ type: 'RESET' });
        dispatchCompany({ type: 'RESET' });
        setRole('');
        setCity('');
        setOther('');
        dispatchAcceptsPolicy({ type: 'RESET' });
      } else {
        setSubmitError('Något gick fel, kunde inte skicka in anmälan.');
      }
    } catch (e) {
      setSubmitError('Något gick fel, kunde inte skicka in anmälan.');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Section backgroundColor='black' textColor='white'>
      <div className='row mb-6'>
        <div className='col-12 col-md-10'>
          <h3 className={`${classes.header} mb-3`}>Registrera dig</h3>
          <p className='text'>
            Välkommen att skicka in en intresseanmälan!
            <br /> Fyll i dina kontaktuppgifter i formuläret, så hör vi av oss
            och berättar mer.
          </p>
        </div>
      </div>
      <form onSubmit={submitHandler}>
        <div className={`${classes['form-row']} row mb-6`}>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={nameRef}
              id='name'
              name='Namn'
              type='text'
              value={nameState.value}
              errorMessage={
                nameState.isValid === false ? 'Fyll i ditt namn.' : undefined
              }
              onBlur={() => dispatchName({ type: 'BLUR' })}
              onChange={e =>
                dispatchName({ type: 'INPUT', val: e.currentTarget.value })
              }
              required
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={emailRef}
              id='email'
              name='E-postadress'
              type='text'
              value={emailState.value}
              errorMessage={
                emailState.isValid === false
                  ? 'Fyll i en korrekt emailadress.'
                  : undefined
              }
              onBlur={() => dispatchEmail({ type: 'BLUR' })}
              onChange={e =>
                dispatchEmail({ type: 'INPUT', val: e.currentTarget.value })
              }
              required
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={phoneRef}
              id='phone'
              name='Telefonnummer'
              type='text'
              value={phoneState.value}
              errorMessage={
                phoneState.isValid === false
                  ? 'Fyll i ett giltigt telefonnummer.'
                  : undefined
              }
              onBlur={() => dispatchPhone({ type: 'BLUR' })}
              onChange={e =>
                dispatchPhone({ type: 'INPUT', val: e.currentTarget.value })
              }
              required
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={companyRef}
              id='company'
              name='Företag'
              type='text'
              value={companyState.value}
              errorMessage={
                companyState.isValid === false
                  ? 'Fyll i företaget du jobbar för.'
                  : undefined
              }
              onBlur={() => dispatchCompany({ type: 'BLUR' })}
              onChange={e =>
                dispatchCompany({ type: 'INPUT', val: e.currentTarget.value })
              }
              required
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              id='role'
              name='Roll'
              type='text'
              value={role}
              onChange={e => setRole(e.currentTarget.value)}
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              id='city'
              name='Ort'
              type='text'
              value={city}
              onChange={e => setCity(e.currentTarget.value)}
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              id='other'
              name='Övrigt'
              type='text'
              value={other}
              onChange={e => setOther(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className={`row mb-6 ${classes['checkbox-row']}`}>
          <div className='col-11 col-sm col-md col-lg'>
            <FormGdprCheckbox
              id='gdpr-checkbox'
              ref={policyRef}
              isValid={acceptsPolicyState.isValid}
              value={acceptsPolicyState.value}
              onBlur={() => dispatchAcceptsPolicy({ type: 'BLUR' })}
              onChange={e =>
                dispatchAcceptsPolicy({
                  type: 'INPUT',
                  val: e.currentTarget.checked,
                })
              }
            />
          </div>
        </div>
        <div className={`row ${classes['button-row']}`}>
          <div className='col'>
            <Button color='white' type='submit' disabled={isSubmitting}>
              {isSubmitting ? 'Skickar...' : 'Skicka'}
            </Button>
          </div>
        </div>
        {submitError && (
          <div className={`row ${classes['submit-error-row']}`}>
            <div className='col'>
              <p>{submitError}</p>
            </div>
          </div>
        )}
        {submitSuccessful && (
          <div className={`row ${classes['submit-successful-row']}`}>
            <div className='col'>
              <p>Anmälan skickad!</p>
            </div>
          </div>
        )}
      </form>
    </Section>
  );
};

export default BasicCourseForm;
