import arrowIcon from '../assets/icons/arrow.svg';
import classes from './WorkAtIceberryRow.module.scss';
import yellowArrowIcon from '../assets/images/yellow-arrow.webp';

const WorkAtIceberryRow: React.FC<{
  title: string;
  text: string;
  additionalClasses?: string;
}> = props => {
  return (
    <div
      className={`row space-between ${classes['row-extension']} ${props.additionalClasses}`}
    >
      <div className='col-10 col-md-5 d-flex d-md-flex'>
        <h5 className={classes['self-center']}>{props.title}</h5>
      </div>
      <div className={`col-2 d-flex ${classes['image-container']}`}>
        <img
          src={arrowIcon}
          alt='An arrow'
          className={`${classes['black-arrow']} ${classes['self-end']}`}
        />
        <img
          src={yellowArrowIcon}
          alt='An arrow'
          className={`${classes['yellow-arrow']} ${classes['self-center']}`}
        />
      </div>
      <div className='col-8 col-md-5'>
        <p className='text'>{props.text}</p>
      </div>
    </div>
  );
};

export default WorkAtIceberryRow;
