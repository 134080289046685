import Hero from '../components/Hero';
import bulbImg from '../assets/images/Glödlampa.webp';
import yellowArrow from '../assets/images/yellow-arrow.webp';
import blackArrow from '../assets/icons/arrow.svg';
import holdingHandsImg from '../assets/images/Händer_Blå.webp';
import mountainImg from '../assets/images/Berg_Blå_Cropped.webp';
import boyImg from '../assets/images/Pojke_Blå.webp';
import valuesImg from '../assets/images/Illustration_Värderingar.svg';
import portraitImg from '../assets/images/DN-our-dna.webp';
import quoteImg from '../assets/icons/quote.svg';

import classes from './About.module.scss';
import Section from '../components/Section';
import TextOverImageSection from '../components/TextOverImageSection';
import LinkButton from '../components/LinkButton';
import ImageText from '../components/ImageText';
import FloatingQuote from '../components/FloatingQuote';
import SeoComponent from '../components/HeadComponent';

const heroHeader = 'Vårt team består av dedikerade och erfarna konsulter';
const heroText =
  'Med passion för teknik drivs vi av att skapa förändring genom teknologi';

const togetherText =
  'Vi engagerar oss i varandra som människor och ställer upp för varandra. Genom genuint intresse och nyfikenhet med vilja att förstå skapar vi en tillitsfull atmosfär. Genom att kommunicera, samarbeta och ha roligt tillsammans bygger vi inte bara projekt, utan även relationer.';
const developmentText =
  'Vi förstår att kompetens är en kontinuerlig process, inte ett slutmål. Vi investerar i vår egen utveckling och strävar ständigt efter att lära oss nya saker. Vi delar med oss av vår kunskap, för vi tror på att gemensam kunskap höjer oss alla och främjar kreativitet.';
const responsibilityText =
  'Vi tror på att engagemang inspirerar och skapar förutsättningar för samarbete. Genom att också ta ansvar blir vi trovärdiga. Vi tar ansvar genom att kommunicera öppet kring både möjligheter och utmaningar och genom att agera utifrån kundens bästa, eftersom vi vill att våra kunder ska lyckas.';

export default function AboutPage() {
  return (
    <>
      <SeoComponent
        description='Lär känna Iceberry – vi drivs av att leverera innovativa lösningar och bygga långsiktiga relationer. Läs mer om oss, vår expertis och våra värderingar.'
        keywords='Om Iceberry,IT-konsultföretag i Sverige,Vårt team,Företagskultur,Vision och värderingar,Partnerskap IT,Möt vårt team,Ledande IT-konsulter,Företagsinnovation IT,Kundrelationer IT,Teknisk kompetens'
      />

      <Hero
        image={bulbImg}
        imageAlt='A hand holding a light bulb'
        headerText={heroHeader}
        text={heroText}
        textColor='white'
        textPos='left'
        imageOverlay='#00000020'
      />
      <Section backgroundColor='light-varm' textColor='black'>
        <div className='row center'>
          <div className='col-12 col-md-8'>
            <h5 className='mb-3'>Det var en gång...</h5>
            <div className='text'>
              ... då gamla manuella arbetsmoment började ersättas av nya
              automatiserade processer. Samtidigt ökade fokus på en ständigt
              växande digital flora av produkter och tjänster. Detta skifte
              krävde även mer av dem som skulle utveckla och underhålla den nya
              digitala infrastrukturen.
            </div>
          </div>
        </div>
      </Section>
      <Section backgroundColor='black' textColor='white'>
        <div className={`row mb-6 ${classes['text-arrow-row']}`}>
          <div className='col'>
            <p className='large-text'>
              Med den insikten och viljan att vara en del av skapandet av det
              nya digitala samhället föddes Inceptive 2006. Inceptives idé var
              att göra duktiga utvecklingsintensiva team ännu bättre genom att
              hjälpa dem med test och kvalitetssäkring och därmed skapa grym
              mjukvara. Iceberry grundades några år senare, 2015. Iceberry
              skapades som ett IT-konsultföretag med fokus på utvecklingsnära
              tjänster, med en vilja att särskilja sig genom ett starkt fokus på
              konsulterna. Övertygelsen var att om man mår bra presterar man bra
              och att varje medarbetare skulle bli sedd och hörd.
            </p>
          </div>
          <div className='d-none col-md'>
            <img
              src={yellowArrow}
              alt='A yellow arrow'
              className={classes.arrow}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-md-6 mb-3 mb-md-0 text'>
            Inceptive och Iceberry kom att bli systerföretag och vi kom fram
            till att vi ser mjukvaruutveckling och mjukvarutestning som olika
            sidor av samma mynt. Båda sidor är avgörande för framställningen av
            pålitlig och effektiv programvara: med samma mål men från olika
            perspektiv och med olika syften. Trots deras olika roller är
            mjukvaruutveckling och mjukvarutestning djupt sammanflätade
            processer.
          </div>
          <div className='col-12 col-md-6 text'>
            Det är anledningen till att Inceptive och Iceberry 2022 gick samman
            till ett företag. Vi behöll namnet Iceberry och kompletterade vårt
            erbjudande med kompetensen från båda sidor av myntet.
          </div>
        </div>
      </Section>
      <Section
        backgroundColor='yellow'
        textColor='black'
        paddingTop='small'
        paddingBottom='small'
      >
        <div className='row center'>
          <div className='col-12 col-md-10'>
            <p className={`${classes.center} large-text`}>
              På Iceberry odlar vi vår personliga utveckling och karriär, trivs
              med kollegor i såväl arbete som i sociala sammanhang och inte
              minst får vi möjlighet till omväxlande och värdeskapande
              arbetsuppgifter.
            </p>
          </div>
        </div>
      </Section>
      <TextOverImageSection
        backgroundColor='light-varm'
        textColor='black'
        image={portraitImg}
        imageAlt='TBA'
        imagePosition='right'
        colWidth='5'
        heightDesktopPx={800}
      >
        <p className='mb-3 large-text'>
          Vi tror på att bygga djupa och långsiktiga relationer och är stolta
          över vår förmåga att tillföra värde till kunder i många olika
          branscher.
        </p>
        <p className='mb-2 text'>
          Därför jobbar vi gärna med små och medelstora kunder, just för att det
          är här vi oftast hittar de mest intressanta projekten där våra
          medarbetare tillför riktiga värden.
        </p>
        <p className='mb-2 text'>
          För att åstadkomma det förlitar vi oss fullt ut våra medarbetare. Med
          mottot att med rätt medarbetare kan man åstadkomma vad som helst har
          vi därför byggt Iceberry som en samlingsplats för de bästa i
          branschen.
        </p>
        <p className='text'>
          Vi vill vara ett företag där man känner tillhörighet och vill utgöra
          en viktig del.
        </p>
      </TextOverImageSection>

      <FloatingQuote colWidth='12'>
        <div className={`mb-1 ${classes.quote}`}>
          <img
            src={quoteImg}
            alt='Quotation marks'
            className={`${classes['quote-img']}`}
          />
          <p className='quote'>
            Sedan starten har våra konsulter, genom sin expertis, varit
            involverade i hundratals kundresor, i alla möjliga branscher.
          </p>
        </div>
        <div className={`${classes.quoter}`}>
          <h5>Daniel Nussdorf, VD</h5>
        </div>
      </FloatingQuote>

      <Section backgroundColor='black' textColor='white' paddingTop='large'>
        <div className='row center mb-6'>
          <div className='col-12 col-md-5 mb-3 mb-md-0 large-text'>
            Lär känna Iceberry!
          </div>
          <div className='col-12 col-md-5 text'>
            <div>
              Är du nyfiken på hur vi skulle kunna hjälpa dig och ditt företag?
              Hör av dig till oss, så tar vi ett samtal.
            </div>
          </div>
        </div>
        <div className='row center'>
          <div className='col'>
            <LinkButton to='/contact' color='white'>
              Kontakta oss
            </LinkButton>
          </div>
        </div>
      </Section>
      <Section
        backgroundColor='yellow'
        textColor='black'
        paddingBottom='small'
        paddingTop='small'
      >
        <div className={`row center ${classes['text-arrow-row']}`}>
          <div className='col-12 col-md-6 large-text'>
            På Iceberry låter vi våra olikheter komplettera varandra, och vi
            enas i våra värderingar:
          </div>
          <div className='d-none col-md-2'>
            <img
              src={blackArrow}
              alt='A yellow arrow'
              className={`${classes.arrow} ${classes.black}`}
            />
          </div>
        </div>
      </Section>
      <div className={classes.values} id='our-values'>
        <div className={classes.value}>
          <ImageText
            title='Gemenskap med omtanke om varandra'
            image={holdingHandsImg}
            overlayColor='light-varm'
          >
            {togetherText}
          </ImageText>
        </div>
        <div className={classes.value}>
          <ImageText
            title='Kompetens genom kontinuerlig utveckling'
            image={mountainImg}
            overlayColor='light-varm'
          >
            {developmentText}
          </ImageText>
        </div>
        <div className={classes.value}>
          <ImageText
            title='Engagemang med mod att ta ansvar'
            image={boyImg}
            overlayColor='light-varm'
          >
            {responsibilityText}
          </ImageText>
        </div>
      </div>
      <Section backgroundColor='black' textColor='white'>
        <div className='row center'>
          <div className='col'>
            <img
              src={valuesImg}
              alt='TBA'
              className={classes['values-illustration']}
            />
          </div>
        </div>
      </Section>
    </>
  );
}
