type ReducerState = {
  value: string;
  isValid: boolean | null;
};

type Input = { type: 'INPUT'; val: string };
type Blur = { type: 'BLUR' };
type Reset = { type: 'RESET' };

type ReducerActions = Input | Blur | Reset;

export function phoneReducer(
  state: ReducerState,
  action: ReducerActions
): ReducerState {
  const regex = /^[+\0-9-]+$/;

  if (action.type === 'INPUT') {
    return { value: action.val, isValid: regex.test(action.val) };
  }
  if (action.type === 'BLUR') {
    return { value: state.value, isValid: regex.test(state.value) };
  }
  if (action.type === 'RESET') {
    return phoneInitialState;
  }
  return { value: '', isValid: false };
}

export const phoneInitialState = {
  value: '',
  isValid: null,
};
