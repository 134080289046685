type ReducerState = {
  value: boolean;
  isValid: boolean | null;
};

type Input = { type: 'INPUT'; val: boolean };
type Blur = { type: 'BLUR' };
type Reset = { type: 'RESET' };

type ReducerActions = Input | Blur | Reset;

export function checkboxReducer(
  state: ReducerState,
  action: ReducerActions
): ReducerState {
  if (action.type === 'INPUT') {
    return {
      value: action.val,
      isValid: action.val,
    };
  }
  if (action.type === 'BLUR') {
    return {
      value: state.value,
      isValid: state.value,
    };
  }
  if (action.type === 'RESET') {
    return checkboxInitialState;
  }
  return { value: false, isValid: false };
}

export const checkboxInitialState = {
  value: false,
  isValid: null,
};
