import classes from './TabHeader.module.scss';

const TabHeader: React.FC<{
  headerText: string;
  headerColor: 'white' | 'black' | 'yellow';
  borderColor: 'white' | 'black' | 'yellow';
}> = props => {
  return (
    <div className='row'>
      <div className={`col ${classes.root}`}>
        <div
          className={`${classes.container} ${
            classes[`b-${props.borderColor}`]
          }`}
        >
          <h5
            className={`${classes.text} ${classes[`c-${props.headerColor}`]}`}
          >
            {props.headerText}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default TabHeader;
