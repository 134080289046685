import Section from './Section';
import classes from './BasicCourseForm.module.scss';
import FormInput from './FormInput';
import { useReducer, useRef, useState } from 'react';
import { emailInitialState, emailReducer } from '../reducers/email-reducer';
import Button from './Button';
import FormGdprCheckbox from './FormGdprCheckbox';
import {
  checkboxInitialState,
  checkboxReducer,
} from '../reducers/checkbox-reducer';

const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL ||
  'https://europe-west1-admin-publicering.cloudfunctions.net';

const SubscriptionForm: React.FC<{}> = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const policyRef = useRef<HTMLInputElement>(null);

  const [emailState, dispatchEmail] = useReducer(
    emailReducer,
    emailInitialState
  );

  const [acceptsPolicyState, dispatchAcceptsPolicy] = useReducer(
    checkboxReducer,
    checkboxInitialState
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [submitError, setSubmitError] = useState('');

  async function submitHandler(event: any) {
    event.preventDefault();
    if (!emailState.isValid) {
      emailRef.current!.focus();
      return;
    }
    if (!acceptsPolicyState.isValid) {
      policyRef.current!.focus();
      return;
    }

    const requestData = {
      email: emailState.value,
    };

    try {
      setIsSubmitting(true);
      setSubmitError('');
      setSubmitSuccessful(false);
      const response = await fetch(BASE_API_URL + '/subscribeToRoles', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: requestData }),
      });

      if (!response) {
        setSubmitError('Något gick fel, kunde inte skicka in anmälan.');
        return;
      }

      const responseJson = await response.json();
      console.log('responseJson', responseJson);
      const result = responseJson.result;

      if (result.status) {
        setSubmitSuccessful(true);
        dispatchEmail({ type: 'RESET' });
        dispatchAcceptsPolicy({ type: 'RESET' });
      } else {
        if (result.errorCode === 1) {
          setSubmitError(result.error);
        } else {
          setSubmitError('Något gick fel, kunde inte skicka in anmälan.');
        }
      }
    } catch (e) {
      setSubmitError('Något gick fel, kunde inte skicka in anmälan.');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Section backgroundColor='black' textColor='white'>
      <div className='row mb-6'>
        <div className='col-12 col-md-10'>
          <h3 className={`${classes.header} mb-3`}>
            Vill du veta när vi publicerar nya uppdrag?
          </h3>
          <p className='text'>
            Anmäl dig här, så får du ett e-mail direkt när vi publicerar ett
            nytt uppdrag!
          </p>
        </div>
      </div>
      <form onSubmit={submitHandler}>
        <div className={`${classes['form-row']} row mb-6`}>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={emailRef}
              id='subscription-email'
              name='E-postadress'
              type='text'
              value={emailState.value}
              errorMessage={
                emailState.isValid === false
                  ? 'Fyll i en korrekt emailadress.'
                  : undefined
              }
              onBlur={() => dispatchEmail({ type: 'BLUR' })}
              onChange={e =>
                dispatchEmail({ type: 'INPUT', val: e.currentTarget.value })
              }
              required
            />
          </div>
        </div>
        <div className={`row mb-6 ${classes['checkbox-row']}`}>
          <div className='col-11 col-sm col-md col-lg'>
            <FormGdprCheckbox
              id='subscription-gdpr-checkbox'
              ref={policyRef}
              isValid={acceptsPolicyState.isValid}
              value={acceptsPolicyState.value}
              onBlur={() => dispatchAcceptsPolicy({ type: 'BLUR' })}
              onChange={e =>
                dispatchAcceptsPolicy({
                  type: 'INPUT',
                  val: e.currentTarget.checked,
                })
              }
            />
          </div>
        </div>
        <div className={`row ${classes['button-row']}`}>
          <div className='col'>
            <Button color='white' type='submit' disabled={isSubmitting}>
              {isSubmitting ? 'Skickar...' : 'Skicka'}
            </Button>
          </div>
        </div>
        {submitError && (
          <div className={`row ${classes['submit-error-row']}`}>
            <div className='col'>
              <p>{submitError}</p>
            </div>
          </div>
        )}
        {submitSuccessful && (
          <div className={`row ${classes['submit-successful-row']}`}>
            <div className='col'>
              <p>Anmälan skickad!</p>
            </div>
          </div>
        )}
      </form>
    </Section>
  );
};

export default SubscriptionForm;
