type ReducerState = {
  value: string;
  isValid: boolean | null;
};

type Input = { type: 'INPUT'; val: string };
type Blur = { type: 'BLUR' };
type Reset = { type: 'RESET' };

type ReducerActions = Input | Blur | Reset;

export function emailReducer(
  state: ReducerState,
  action: ReducerActions
): ReducerState {
  if (action.type === 'INPUT') {
    return { value: action.val, isValid: action.val.includes('@') };
  }
  if (action.type === 'BLUR') {
    return { value: state.value, isValid: state.value.includes('@') };
  }
  if (action.type === 'RESET') {
    return emailInitialState;
  }
  return { value: '', isValid: false };
}

export const emailInitialState = {
  value: '',
  isValid: null,
};
