import CourseContentList from '../../components/CourseContentList';
import Hero from '../../components/Hero';
import Section from '../../components/Section';
import TabHeader from '../../components/TabHeader';
import classes from './Accessibility.module.scss';

import grassImg from '../../assets/images/Gräsmatta.webp';
import yellowArrow from '../../assets/images/yellow-arrow.webp';
import BasicCourseForm from '../../components/BasicCourseForm';

const heroText =
  'Lär dig att skapa inkluderande digitala upplevelser genom att förstå och implementera tillgänglighetsprinciper för webbplatser och appar.';

const contentListItems1 = [
  'Tillgänglighet ur ett användbarhetsperspektiv',
  'Relationen mellan individens roll och dess förmåga att uppnå ett ändamål',
  'Djupdykning i de olika funktionsvariationerna: Vilka är de? Statistisk överblick på populationsnivå. Hur påverkar respektive funktionsvariation en design?',
  'Nyckelområden för applicerad användbarhet, bl.a. Web: browsers, mobilt; Hårdvara: informationstavlor, kontrollpaneler; De 7 produktkategorierna',
  'Problematisering med exempel på olika situationer',
];

const contentListItems2 = [
  'Lagkrav - vad förväntas av samhället?',
  'Rekommendationer eller krav: Webbtillgänglighetsdirektivet, Offentlig sektor & Privat sektor',
  'ISO/IEC 40500 och WCAG 2.1',
  'Relationen mellan EN301549 och WCAG',
  'De olika WCAG nivåerna och hur dessa uppfylls',
  'WCAG historik, utveckling efter 2025',
];

export default function AccessibilityPage() {
  return (
    <>
      <Hero
        image={grassImg}
        imageAlt='A shadow of a human on some plastic grass'
        imageOverlay='#00000050'
        textPos='left'
        textColor='white'
        headerText='Tillgänglighetskurs'
        headerColor='yellow'
        text={heroText}
      />
      <Section
        backgroundColor='black'
        textColor='white'
        paddingBottom='large'
        rounded='top'
      >
        <div className='row'>
          <div className={`col-12 text ${classes.col}`}>
            <div className={`d-none d-lg-block ${classes.arrow}`}>
              <img src={yellowArrow} alt='A yellow arrow' />
            </div>
            <h4 className='mb-3'>Tillgänglighet</h4>
            <p className='mb-2'>
              Tillgänglighet - nyckeln till att skapa inkluderande digitala
              upplevelser. Oavsett vad du har för roll, om du är erfaren
              professionell webbdesigner eller arbetar med test och kvalitet
              eller är ledare inom tech, ger dig denna kurs de insikter och
              verktyg du behöver för att skapa innehåll som når ut till alla
              potentiella användare. På samma gång får du upptäcka de senaste
              riktlinjerna och för att säkerställa att din digitala närvaro når
              upp till det moderna samhällets förväntningar.
            </p>
            <p>
              Kursen baseras på två moduler som tillsammans tar dig genom resan
              till att bli en föregångare inom inkluderande design, så att du
              kan göra skillnad i den digitala världen!
            </p>
          </div>
        </div>
      </Section>
      <TabHeader
        headerText='Kursen berör följande:'
        headerColor='white'
        borderColor='yellow'
      />
      <div className={classes.lists}>
        <CourseContentList
          backgroundColor='yellow'
          textColor='black'
          headerText='Modul 1 - Bygga förståelse'
          listItems={contentListItems1}
        />
        <CourseContentList
          backgroundColor='yellow'
          textColor='black'
          headerText='Modul 2 - Bygga färdighet'
          listItems={contentListItems2}
        />
      </div>
      <BasicCourseForm courseName='Tillgänglighet' />
    </>
  );
}
