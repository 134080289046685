import classes from './EmployeeCarouselSlideIndicators.module.scss';

/**
 * The circles below the employee carousel indicating which slide is active
 * and possibility to change active slide
 */
export default function EmployeeCarouselSlideIndicators(props: {
  activeSlideIndex: number;
  setSlide: (index: number) => void;
}) {
  return (
    <div className={`${classes['circle-container']}`}>
      <div
        onClick={() => props.setSlide(0)}
        className={`${classes.circle} ${
          props.activeSlideIndex === 0 ? classes['active-circle'] : ''
        }`}
      ></div>
      <div
        onClick={() => props.setSlide(1)}
        className={`${classes.circle} ${
          props.activeSlideIndex === 1 ? classes['active-circle'] : ''
        }`}
      ></div>
      <div
        onClick={() => props.setSlide(2)}
        className={`${classes.circle} ${
          props.activeSlideIndex === 2 ? classes['active-circle'] : ''
        }`}
      ></div>
    </div>
  );
}
