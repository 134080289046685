import { mdBreakpoint } from '../style/breakpoint-numbers';
import Section from './Section';
import classes from './TextOverImageSection.module.scss';

const TextOverImageSection: React.FC<{
  children: React.ReactNode;
  backgroundColor: 'black' | 'yellow' | 'light-varm';
  textColor: 'black' | 'white';
  image: string;
  imageAlt: string;
  imagePosition: 'left' | 'right';
  colWidth:
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12';
  heightDesktopPx: number;
}> = props => {
  const vw = window.innerWidth;
  let containerStyle = {
    height: vw >= mdBreakpoint ? props.heightDesktopPx : undefined,
  };

  return (
    <div
      style={containerStyle}
      className={`${classes.container} ${
        props.imagePosition === 'right' ? classes['image-right'] : ''
      }`}
    >
      <div className={classes['image-container']}>
        <img src={props.image} alt={props.imageAlt} />
      </div>
      <div className={`${classes['content-container-desktop']} row`}>
        <div
          className={`${classes.col} col-${props.colWidth} ${
            classes[`image-${props.imagePosition}`]
          }`}
        >
          <div
            className={`${classes['content-background']} ${
              classes[`bg-${props.backgroundColor}`]
            } ${classes[`image-${props.imagePosition}`]}`}
          ></div>
          <div
            className={`${classes.content} ${classes[`c-${props.textColor}`]}`}
          >
            <div>{props.children}</div>
          </div>
        </div>
      </div>
      <Section
        backgroundColor={props.backgroundColor}
        textColor={props.textColor}
        className={classes['content-container-mobile']}
        rounded='top'
      >
        <div className='row'>
          <div className='col-12'>{props.children}</div>
        </div>
      </Section>
    </div>
  );
};

export default TextOverImageSection;
