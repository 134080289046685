import logo from '../assets/images/logo.png';
import planeIcon from '../assets/icons/plane.svg';
import phoneIcon from '../assets/icons/phone.svg';
import linkedinIcon from '../assets/icons/linkedin.svg';

import classes from './Footer.module.scss';
import AnimatedLink from './AnimatedLink';

export default function Footer() {
  return (
    <footer className={classes.container}>
      <div className={`row ${classes.row}`}>
        <div className={`col-12 col-md-2 ${classes.pages}`}>
          <div className='large-text mb-3'>Sidor</div>
          <div className={`${classes['link-list']} text`}>
            <AnimatedLink to='/'>Hem</AnimatedLink>
            <AnimatedLink to='/about'>Vårt DNA</AnimatedLink>
            <AnimatedLink to='/career'>Jobba hos oss</AnimatedLink>
            <AnimatedLink to='/assignments'>Uppdrag</AnimatedLink>
            <AnimatedLink to='/courses'>Våra kurser</AnimatedLink>
            <AnimatedLink to='/contact'>Kontakta oss</AnimatedLink>
          </div>
        </div>
        <div className={`col-12 col-md-3 text ${classes.contact}`}>
          <div className='large-text mb-3'>Kontakta oss</div>
          <div className={`${classes['contact-list']} text`}>
            <div>
              <img src={planeIcon} className='icon white' alt='A paper plane' />
              <a href='mailto:hello@iceberry.se'>hello@iceberry.se</a>
            </div>
            <div>
              <img src={phoneIcon} className='icon white' alt='A phone' />
              <a href='tel:+46106600630'>+46 (0)10-660 06 30</a>
            </div>
            <div className={classes.linkedIn}>
              <img
                src={linkedinIcon}
                className='icon white'
                alt='LinkedIn logo'
              />
              <a
                href='https://www.linkedin.com/company/iceberry/'
                target='blank'
              >
                LinkedIn
              </a>
            </div>
          </div>
        </div>
        <div className={`col mb-3 mb-md-0 ${classes.logo}`}>
          <img src={logo} alt='Iceberry logo' />
        </div>
      </div>
    </footer>
  );
}
