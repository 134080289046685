import classes from './AssignmentDetail.module.scss';
import { Await, defer, json, useLoaderData } from 'react-router-dom';
import Section from '../components/Section';
import { Suspense, useEffect, useState } from 'react';
import pinIcon from '../assets/icons/pin.svg';
import stopwatchIcon from '../assets/icons/stopwatch.svg';
import planeIcon from '../assets/icons/plane.svg';
import Button from '../components/Button';
import { lgBreakpoint } from '../style/breakpoint-numbers';
import Loading from '../components/Loading';

export default function AssignmentDetailPage() {
  const { assignment } = useLoaderData() as any;
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const fallback = (
    <div className={classes.fallback}>
      <Loading />
    </div>
  );

  const assignScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', assignScreenSize);

    return () => {
      window.removeEventListener('resize', assignScreenSize);
    };
  }, []);

  return (
    <Suspense fallback={fallback}>
      <Await resolve={assignment}>
        {loadedAssignment => (
          <>
            <Section
              backgroundColor='yellow'
              textColor='black'
              paddingTop={screenSize > lgBreakpoint ? 'xl' : 'large'}
              paddingBottom={screenSize > lgBreakpoint ? 'large' : 'default'}
            >
              <div className='row'>
                <div className='col'>
                  <h2 className='mb-6'>{loadedAssignment.externalTitle}</h2>
                  <div className={`${classes.info} mb-3`}>
                    <div className={classes.location}>
                      <img
                        src={pinIcon}
                        alt='A map pin'
                        className='icon black'
                      />
                      <p>{loadedAssignment.location}</p>
                    </div>
                    <div className={classes.deadline}>
                      <img
                        src={stopwatchIcon}
                        alt='A stopwatch'
                        className='icon black'
                      />
                      <p>Ansök senast: {loadedAssignment.deadline}</p>
                    </div>
                  </div>
                  <Button
                    color='black'
                    onClick={() => {
                      window.open(loadedAssignment.brainvilleURL, '_blank');
                    }}
                  >
                    <span className={classes['button-text']}>Ansök nu</span>
                    <img
                      src={planeIcon}
                      alt='A stopwatch'
                      className='icon black'
                    />
                  </Button>
                </div>
              </div>
            </Section>
            <Section backgroundColor='black' textColor='white'>
              <div className='row'>
                <div className='col-12 text'>
                  <div className={`${classes.card}`}>
                    <h5 className='mb-3'>Om tjänsten</h5>
                    <p
                      className='mb-3'
                      dangerouslySetInnerHTML={{
                        __html: loadedAssignment.descriptionHTML,
                      }}
                    ></p>
                    {loadedAssignment.startDate && loadedAssignment.endDate && (
                      <p>
                        Period: {loadedAssignment.startDate} -{' '}
                        {loadedAssignment.endDate}
                      </p>
                    )}
                    {loadedAssignment.deadline && (
                      <p>Ansök senast: {loadedAssignment.deadline}</p>
                    )}
                    <div className={classes.apply}>
                      <Button
                        color='white'
                        onClick={() => {
                          window.open(loadedAssignment.brainvilleURL, '_blank');
                        }}
                      >
                        <span className={classes['button-text']}>Ansök nu</span>
                        <img
                          src={planeIcon}
                          alt='A stopwatch'
                          className='icon white'
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Section>
          </>
        )}
      </Await>
    </Suspense>
  );
}

export function loader({ params }: any) {
  return defer({
    assignment: loadAssignment(params.id!),
  });
}

async function loadAssignment(id: string) {
  const response = await fetch(
    'https://europe-west1-admin-publicering.cloudfunctions.net/getAssignment',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: id }),
    }
  );

  if (!response.ok) {
    throw json(
      { message: 'Could not fetch assignment' },
      {
        status: 500,
      }
    );
  } else {
    const resData = await response.json();
    console.log(resData.result.response);
    return resData.result.response;
  }
}
