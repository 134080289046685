import classes from './FloatingQuote.module.scss';
import Section from './Section';

const FloatingQuoteMobile: React.FC<{ children: React.ReactNode }> = props => {
  return (
    <Section
      backgroundColor='yellow'
      textColor='black'
      rounded='all'
      paddingTop='small'
      paddingBottom='small'
    >
      <div className='row'>
        <div className='col-12'>{props.children}</div>
      </div>
    </Section>
  );
};

const FloatingQuote: React.FC<{
  children: React.ReactNode;
  colWidth:
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12';
}> = props => {
  return (
    <>
      <div className={`${classes['desktop-root']} d-none d-md-block`}>
        <div className={`row ${classes.container}`}>
          <div
            className={`col-12 col-md-${props.colWidth} ${classes['floating-box']}`}
          >
            {props.children}
          </div>
        </div>
      </div>
      <div className='d-block d-md-none'>
        <FloatingQuoteMobile>{props.children}</FloatingQuoteMobile>
      </div>
    </>
  );
};

export default FloatingQuote;
