import { Link } from 'react-router-dom';

import classes from './JobButton.module.scss';
import arrow from '../assets/images/yellow-arrow.webp';

const AssignmentButton: React.FC<{
  linkTo: string;
  title: string;
  smallText: string;
}> = props => {
  return (
    <Link to={`${props.linkTo}`} className={classes.link}>
      <div className={classes.container}>
        <div className={`${classes.text} text`}>
          <div className='mb-1'>{props.title}</div>
          <div className='small-text'>{props.smallText}</div>
        </div>
        <div className={classes.arrow}>
          <img src={arrow} alt='Arrow' />
        </div>
      </div>
    </Link>
  );
};

export default AssignmentButton;
