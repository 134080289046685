import { Employee } from '../models/Employee';

import dnPortraitImage from '../assets/images/employees/Kontakt-DN.webp';
import pkPortraitImage from '../assets/images/employees/Kontakt-PK.webp';
import unPortraitImage from '../assets/images/employees/Kontakt-UN.webp';

export const employees: Employee[] = [
  {
    image: dnPortraitImage,
    name: 'Daniel Nussdorf',
    title: 'CEO',
    email: 'daniel.nussdorf@iceberry.se',
  },
  {
    image: unPortraitImage,
    name: 'Ulrica Nyberg',
    title: 'Partner Manager',
    email: 'ulrica.nyberg@iceberry.se',
  },
  {
    image: pkPortraitImage,
    name: 'Per Klingnäs',
    title: 'Head of Consulting Services',
    email: 'per.klingnas@iceberry.se',
  },
];
