import { AnimatePresence, motion } from 'framer-motion';
import classes from './MobileDrawer.module.scss';
import crossIcon from '../assets/icons/cross.svg';

const variantsDrawer = {
  open: {
    right: '300px',
  },
  closed: {
    right: 0,
  },
};

const variantsShadow = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

const MobileDrawer: React.FC<{
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}> = props => {
  return (
    <>
      <AnimatePresence>
        {props.isOpen && (
          <motion.div
            className={classes.shadow}
            variants={variantsShadow}
            initial='closed'
            animate='open'
            exit='closed'
            onClick={props.onClose}
          ></motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {props.isOpen && (
          <motion.div
            className={classes.container}
            variants={variantsDrawer}
            initial='closed'
            animate='open'
            exit='closed'
          >
            <div className={classes.close}>
              <img
                src={crossIcon}
                className='icon white'
                alt='Menu icon'
                onClick={props.onClose}
              />
            </div>
            {props.children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileDrawer;
