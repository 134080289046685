import { Helmet } from 'react-helmet-async';

/**
 * Component to update meta information in "head" for SEO
 */
export default function SeoComponent(props: {
  /**
   * Sets the meta description for the page
   */
  description?: string;
  /**
   * Sets the meta keywords for the page.
   * Comma-separated string
   */
  keywords?: string;
}) {
  return (
    <>
      <Helmet>
        {props.description && (
          <meta name='description' content={props.description} />
        )}
        {props.keywords && <meta name='keywords' content={props.keywords} />}
      </Helmet>
    </>
  );
}
