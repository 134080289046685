import classes from './ImageOverlay.module.scss';

const ImageOverlay: React.FC<{
  children: React.ReactNode;
  className?: string;
  textColor: 'white' | 'black';
  textPos: 'left' | 'right';
}> = props => {
  return (
    <div className={`${classes.textContainer} ${props.className} row`}>
      <div
        className={`col-12 col-md-10 col-xl-8 ${classes.text} ${
          classes[`c-${props.textColor}`]
        } ${classes[`pos-${props.textPos}`]}`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default ImageOverlay;
