import LinkButton from '../../components/LinkButton';
import Section from '../../components/Section';
import classes from './Integrity.module.scss';

export default function SwedishIntegrity() {
  return (
    <>
      <Section backgroundColor='black' textColor='white' paddingTop='large'>
        <div className='row text'>
          <div className={`col-12 mb-6`}>
            <LinkButton color='white' className='mb-3' to={'/integrity/en'}>
              {'You can find the english version here.'}
            </LinkButton>
            <p>
              {
                'På Iceberry.se värnar vi om din integritet och behandlar dina personuppgifter enligt GDPR. Denna policy beskriver hur vi samlar in, använder och skyddar dina uppgifter.'
              }
            </p>
          </div>
        </div>

        <div className='row text'>
          <div className='col-12 mb-5'>
            <h4 className='mb-1'>1. Personuppgiftsansvarig</h4>
            <p className='mb-2'>
              Iceberry AB, är ansvarig för dina personuppgifter. Kontakta oss på{' '}
              <a className={classes.link} href='mailto:privacy@iceberry.se'>
                privacy@iceberry.se
              </a>{' '}
              för frågor om vår hantering.
            </p>
          </div>
        </div>

        <div className='row text'>
          <div className='col-12 mb-5'>
            <h4 className='mb-1'>2. Vilka uppgifter vi samlar in</h4>
            <p>
              Vi samlar endast in personuppgifter som du själv aktivt skickar
              till oss när du använder våra tjänster. Detta kan omfatta:
            </p>
            <ul>
              <li>
                Kontaktinformation (namn, e-post, telefon, företag, roll, ort).
              </li>
              <li>Professionell information (såsom CV, personliga brev).</li>
            </ul>
          </div>
        </div>

        <div className='row text'>
          <div className='col-12 mb-5'>
            <h4 className='mb-1'>3. Så använder vi dina uppgifter</h4>
            <p className='mb-1'>Vi använder dina uppgifter för att:</p>
            <ul>
              <li>Ge dig tillgång till jobb- och uppdragssökning.</li>
              <li>Rekommendera relevanta jobb och konsultuppdrag.</li>
              <li>Kommunicera med dig kring ovannämnda roller.</li>
            </ul>
            <p>
              Vi delar endast dina uppgifter med tredje part, såsom potentiella
              uppdragsgivare, efter överenskommelse, eller när vi är skyldiga
              enligt lag.
            </p>
          </div>
        </div>

        <div className='row text'>
          <div className='col-12 mb-5'>
            <h4 className='mb-1'>4. Dina rättigheter</h4>
            <p className='mb-1'>Du har rätt att:</p>
            <ul>
              <li>Få tillgång till dina uppgifter.</li>
              <li>Begära rättelse eller radering.</li>
              <li>Återkalla ditt samtycke.</li>
            </ul>
          </div>
        </div>

        <div className='row text'>
          <div className='col-12 mb-5'>
            <h4 className='mb-1'>5. Lagring</h4>
            <p>
              Dina uppgifter lagras endast så länge de behövs för att leverera
              tjänsten eller maximalt 2 år.
            </p>
          </div>
        </div>

        <div className='row text'>
          <div className='col-12'>
            <h4 className='mb-1'>6. Kontakt</h4>
            <p>
              Har du frågor? Kontakta oss på{' '}
              <a className={classes.link} href='mailto:privacy@iceberry.se'>
                privacy@iceberry.se
              </a>
              .
            </p>
          </div>
        </div>
      </Section>
    </>
  );
}
