import classes from './Section.module.scss';

const Section: React.FC<{
  children: React.ReactNode;
  backgroundColor: 'black' | 'yellow' | 'true-black' | 'light-varm';
  textColor?: 'white' | 'black';
  rounded?: 'top' | 'bottom' | 'all';
  className?: string;
  zIndex?: number;
  paddingTop?: 'small' | 'default' | 'large' | 'xl';
  paddingBottom?: 'small' | 'default' | 'large' | 'xl';
  id?: string;
}> = props => {
  return (
    <div
      id={props.id}
      style={{ zIndex: props.zIndex ?? undefined }}
      className={`${classes.section} ${props.className} ${
        props.backgroundColor ? classes[`bg-${props.backgroundColor}`] : ''
      } ${props.textColor ? classes[`c-${props.textColor}`] : ''} ${
        props.rounded === 'all' || props.rounded === 'top'
          ? classes['r-top']
          : ''
      } ${
        props.rounded === 'all' || props.rounded === 'bottom'
          ? classes['r-bottom']
          : ''
      } ${
        props.paddingTop !== undefined
          ? classes[`${props.paddingTop}-padding-top`]
          : classes['default-padding-top']
      } ${
        props.paddingBottom !== undefined
          ? classes[`${props.paddingBottom}-padding-bottom`]
          : classes['default-padding-bottom']
      }`}
    >
      {props.children}
    </div>
  );
};

export default Section;
