import Section from '../../components/Section';
import grassImg from '../../assets/images/Gräsmatta.webp';
import yellowArrow from '../../assets/images/yellow-arrow.webp';
import Hero from '../../components/Hero';

import classes from './PerformanceTesting.module.scss';
import TabHeader from '../../components/TabHeader';
import CourseContentList from '../../components/CourseContentList';
import BasicCourseForm from '../../components/BasicCourseForm';

const heroText =
  'Förstå prestandatestning grundligt, så att du kan maximera nyttan genom strategiska val. Teori och praktik.';

const contentListItems1 = [
  'Icke-funktionella egenskaper: Hastighet, stabilitet, skalbarhet, storlek',
  'Icke-funktionella testtyper: Last, stress, volym, skalbarhet',
  'Motivation och intressenter: Marknad, ekonomi, säkerhet',
  'Roller och kompetens: Utveckling eller test?',
];

const contentListItems2 = [
  'Förstå applikationens beteende nu och i framtiden',
  'Undersöka applikationens förväntade egenskaper för att tillmötesgå olika typer av last',
  'Målmiljöns påverkan på testningen, moln/cloud, on-prem, API, Websida, Microservices',
  'Lastbalansering, warm-up, ramp-up, transaktioner, tuning, graceful degradation, back-off-tekniker, polling, synkronisitet, trådning, virtuella användare',
  'Förutstå och tolka resultat samt jämförelse med baselines',
];

const contentListItems3 = [
  'Att förstå applikationen som ska testas',
  'Förstå olika testverktyg och välja rätt egenskaper',
  'Sätta upp skript och förbereda loggning och monitorering',
  'Köra tester samt analysera och rapportera resultat',
];

export default function PerformanceTestingPage() {
  return (
    <>
      <Hero
        image={grassImg}
        imageAlt='A shadow of a human on some plastic grass'
        imageOverlay='#00000050'
        textPos='left'
        textColor='white'
        headerText='Prestandratestning'
        headerColor='yellow'
        text={heroText}
      />
      <Section
        backgroundColor='black'
        textColor='white'
        paddingBottom='large'
        rounded='top'
      >
        <div className='row'>
          <div className={`col-12 text ${classes.col}`}>
            <div className={`d-none d-lg-block ${classes.arrow}`}>
              <img src={yellowArrow} alt='A yellow arrow' />
            </div>
            <h4 className='mb-3'>Icke-funktionell testning - Prestanda</h4>
            <p className='mb-2'>
              Prestanda är en icke-funktionell egenskap som berör hela
              utvecklingsprocessen, från krav till test. Prestandatest kan vara
              som att leta efter en nål i en höstack. I den här kursen kommer du
              att få en gedigen grund att stå på, så att du kan göra strategiska
              val och fokusera din tid på rätt saker. Vi kommer att varva teori
              med praktik, så att du får möjlighet att utforska dina nya
              kunskaper. Kursen erbjuder även ett stort utrymme för diskussion
              och praktiska användarfall, som alla deltagare kan ha nytta av.
            </p>
            <p>
              Den här kursen vänder sig till tekniska testare eller utvecklare
              som vill få en flygande start till sina prestandatester.
              Förkunskapskravet är grundläggande förståelse för programmering.
              Medtag egen dator (Windows, Mac, Linux)
            </p>
          </div>
        </div>
      </Section>
      <TabHeader
        headerText='Kursen berör följande:'
        headerColor='white'
        borderColor='yellow'
      />
      <div className={classes.lists}>
        <CourseContentList
          backgroundColor='yellow'
          textColor='black'
          headerText='Vad är prestandatest?'
          listItems={contentListItems1}
        />
        <CourseContentList
          backgroundColor='yellow'
          textColor='black'
          headerText='Strategier & lastprofilering'
          listItems={contentListItems2}
        />
        <CourseContentList
          backgroundColor='yellow'
          textColor='black'
          headerText='Praktik'
          listItems={contentListItems3}
        />
      </div>
      <BasicCourseForm courseName='Icke-funktionell testning - Prestanda' />
    </>
  );
}
