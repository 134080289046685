import Hero from '../components/Hero';

import TextOverImageSection from '../components/TextOverImageSection';

import heroImg from '../assets/images/Hålla_Händer.webp';
import peopleImg from '../assets/images/Samlade_Människor2.webp';
import arrowIcon from '../assets/icons/arrow.svg';

import classes from './Career.module.scss';
import Section from '../components/Section';
import LinkButton from '../components/LinkButton';
import EmployeeCarousel from '../components/EmployeeCarousel/EmployeeCarousel';
import WorkAtIceberryRow from '../components/WorkAtIceberryRow';
import SeoComponent from '../components/HeadComponent';

export default function CareerPage() {
  return (
    <>
      <SeoComponent
        description='Jobba hos oss på Iceberry – vi värdesätter att utvecklas och ha kul tillsammans. Utforska våra jobbmöjligheter och bli en del av vårt team av IT-konsulter.'
        keywords='IT-karriär,Jobb inom IT,Lediga tjänster IT,Karriär på Iceberry,IT-jobbmöjligheter,IT-konsultjobb,Karriärutveckling IT,Jobba som IT-konsult,IT-talang sökes,Karriär inom digital innovation,Jobb för backendutvecklare,Jobb för tekniska testare,Ansök till Iceberry'
      />

      <Hero
        image={heroImg}
        imageAlt='Two hands with their little fingers holding each other'
        imageOverlay='#00000030'
        headerText='Vi utvecklas gemensamt och har kul tillsammans'
        text='Vi är en brokig skara individer inom utveckling och test och vi vill gärna få fler kollegor!'
        textPos='left'
        textColor='white'
      />
      <Section backgroundColor='yellow' textColor='black'>
        <div className={`row ${classes['text-arrow-row']}`}>
          <div className='d-none col-md-3'></div>
          <div className='col-12 col-md-6'>
            <p className={`large-text mb-3 ${classes.center}`}>
              Vill du bli en del av vårt gäng? Är du erfaren inom
              backendutveckling eller teknisk testning? Vi vill ha fler
              kollegor, så läs om våra öppna positioner och sök till oss redan
              idag!
            </p>
            <div className={classes.link}>
              <LinkButton to='/career/roles' color='black'>
                Lediga tjänster
              </LinkButton>
            </div>
          </div>
          <div className='d-none col-md'>
            <img src={arrowIcon} alt='An arrow' className={classes.arrow} />
          </div>
        </div>
      </Section>
      <TextOverImageSection
        backgroundColor='black'
        textColor='white'
        image={peopleImg}
        imageAlt='The silouettes of five people standing together on some kind of rock'
        imagePosition='left'
        colWidth='6'
        heightDesktopPx={800}
      >
        <h5 className={`mb-3 ${classes['yellow-header']}`}>
          Vet du vad som är så speciellt med att jobba på Iceberry?
        </h5>
        <p className='mb-2 text'>
          Det är känslan av <b>gemenskap med omtanke om varandra</b> som
          genomsyrar allt vi gör. Vi är en brokig skara individer som alla har
          jobbat länge med utveckling och kvalitetssäkring av mjukvara och möts
          i att vi tycker om att ha roligt tillsammans.
        </p>
        <p className='mb-2 text'>
          Vi strävar alltid efter att bli bättre, både som individer och som
          grupp, och tycker att <b>kompetens genom kontinuerlig utveckling</b>{' '}
          är viktigt för att vi ska kunna dela med oss av vår kunskap till både
          våra kunder och till varandra. Det handlar om att ständigt lära och
          utvecklas tillsammans.
        </p>
        <p className='text'>
          Något annat som är viktigt för oss är{' '}
          <b>engagemang med mod att ta ansvar</b>. Vi vill verkligen se våra
          kunder blomstra och lyckas, så varje dag är en möjlighet att göra
          skillnad.
        </p>
      </TextOverImageSection>

      <EmployeeCarousel />

      <div className={classes['work-at-iceberry-section']}>
        <h4 className='d-none d-md-block col-md-12 text-center'>
          Så här fungerar det att jobba hos oss
        </h4>
        <div className={`col-md-10 ${classes['work-at-iceberry-sub-section']}`}>
          <WorkAtIceberryRow
            title='VI BÖRJAR MED DIG'
            text='Vi börjar med att titta på din erfarenhet och kompetens, dina
          intressen och din potential. Tillsammans skapar vi sedan en
          individuell målbild, både när det kommer till ditt uppdrag och hur
          du vill utvecklas framåt.'
            additionalClasses='mb-4 mb-md-6'
          />

          <WorkAtIceberryRow
            title='DITT MÅL ÄR VÅRT MÅL'
            text='Utifrån målbilden går vi vidare med att identifiera flera
          spännande uppdrag och möjligheter som ligger i linje med dina
          önskemål.'
            additionalClasses='mb-4 mb-md-6'
          />

          <WorkAtIceberryRow
            title='DU VÄLJER'
            text='På Iceberry strävar vi efter en optimal matchmaking, både för
          kunden och för våra medarbetare. Vi är övertygade om att du blir
          mer motiverad och därför både presterar och mår bättre om du är
          nöjd.'
            additionalClasses='mb-4 mb-md-6'
          />

          <WorkAtIceberryRow
            title='VI VINNER TILLSAMMANS'
            text='Vi tror på att alla drar nytta av att våra medarbetare mår bra i
          sina uppdrag. När både du och kunden är nöjda vinner vi på
          Iceberry långsiktighet med återkommande kunder.'
          />
        </div>
      </div>
    </>
  );
}
