import { useEffect, useState } from 'react';

import wavingImg from '../assets/images/Vinka.jpg';

import classes from './Assignments.module.scss';

import Hero from '../components/Hero';
import Section from '../components/Section';
import JobButton from '../components/JobButton';

const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL ||
  'https://europe-west1-admin-publicering.cloudfunctions.net';

const heroHeader = 'Vill du bli en del av vårt gäng?';
const heroText = 'Här är våra öppna positioner';

export default function RolesPage() {
  const [roles, setRoles] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(BASE_API_URL + '/getActiveInternalRoles', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: {} }),
    })
      .then(response => response.json())
      .then(responseData => {
        setRoles(responseData.result.data);
      })
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Hero
        image={wavingImg}
        imageAlt='A man waving to his shadow'
        headerText={heroHeader}
        text={heroText}
        textPos='left'
        textColor='white'
        imageOverlay='#00000060'
      />
      <Section backgroundColor='black' textColor='white'>
        <div className='row'>
          <h3 className={`${classes.header} mb-6 col-12`}>Jobba hos oss</h3>
        </div>
        <div className='row text'>
          {!isLoading && roles.length === 0 && (
            <div className='col-12'>Just nu har vi inga öppna positioner</div>
          )}
          {isLoading && <div className='col-12'>Hämtar...</div>}
          <div className={`${classes.list} col-12`}>
            {roles.length > 0 &&
              !isLoading &&
              roles.map(role => (
                <JobButton
                  key={role.id}
                  title={role.title}
                  smallText={role.location}
                  linkTo={'/career/roles/' + role.id}
                />
              ))}
          </div>
        </div>
      </Section>
    </>
  );
}
