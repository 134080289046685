import { AnimatePresence, motion } from 'framer-motion';
import classes from './ImageText.module.scss';
import { useState } from 'react';
import Section from './Section';
import LinkButton from './LinkButton';

const variants = {
  normal: {
    opacity: 0,
  },
  hover: {
    opacity: 1,
  },
};

const ImageText: React.FC<{
  children: React.ReactNode;
  title: string;
  image: string;
  overlayColor: 'light-varm' | 'yellow' | 'black';
  link?: {
    linkTo: string;
    linkText: string;
    linkColor: 'black' | 'white';
  };
}> = props => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={classes.container}>
      <img src={props.image} alt='TBA' />
      <motion.div
        className={`${classes.header} ${classes['desktop-only']}`}
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
      >
        <h4>{props.title}</h4>
        <AnimatePresence>
          {isHovered && (
            <motion.div
              className={`${classes.overlay} ${
                classes[`bg-${props.overlayColor}`]
              }`}
              variants={variants}
              initial='normal'
              animate='hover'
              exit='normal'
              transition={{ duration: 0.3 }}
            >
              <div className='text mb-5'>{props.children}</div>
              {props.link && (
                <LinkButton
                  to={props.link.linkTo}
                  color={props.link.linkColor}
                  className='center'
                >
                  {props.link.linkText}
                </LinkButton>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      <div className={classes['mobile-only']}>
        <Section backgroundColor='yellow' rounded='top' zIndex={2}>
          <div className='row mb-5'>
            <div className='col-12 text'>
              <h4 className='mb-3'>{props.title}</h4>
              <div className='mb-2'>{props.children}</div>
            </div>
          </div>
          {props.link && (
            <div className='row center'>
              <LinkButton
                to={props.link.linkTo}
                color={props.link.linkColor}
                className='center'
              >
                {props.link.linkText}
              </LinkButton>
            </div>
          )}
        </Section>
      </div>
    </div>
  );
};

export default ImageText;
