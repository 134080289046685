import classes from './Loading.module.scss';

export default function Loading() {
  return (
    <>
      <span className={`${classes.loader}`}>
        <span className={`${classes.text}`}>Laddar..</span>
      </span>
    </>
  );
}
