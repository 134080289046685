import classes from './Home.module.scss';

import homeDesktop from '../assets/images/Startsida_Cropped.webp';
import homeMobile from '../assets/images/Startsida_Cropped_Mobile.webp';
import arrow from '../assets/images/yellow-arrow.webp';
import mountainImg from '../assets/images/Klättra.webp';
import handsImg from '../assets/images/Hålla_Händer.webp';
import vrWomanImg from '../assets/images/VR.webp';

import Section from '../components/Section';
import LinkButton from '../components/LinkButton';
import ImageText from '../components/ImageText';

const workWithUsText =
  'Vill du bli en del av vårt gäng? Här kan du läsa mer om att jobba hos oss och kolla in våra lediga jobb. Sök till oss redan idag!';
const openAssignmentsText =
  'Är du en av våra branschkollegor som letar nytt konsultuppdrag? Här är de uppdrag vi har tillgängliga just nu. Du kan också registrera ditt CV hos oss, om du vill att vi ska hålla utkik efter uppdrag till dig.';
const coursesText =
  'Utbildning och coachning har alltid funnits i vårt DNA. Här kan läsa mer om hur vi jobbar med det här och se vilka öppna kurser vi erbjuder.';

export default function HomePage() {
  return (
    <>
      <div className={classes.banner}>
        <img className={classes['image-desktop']} src={homeDesktop} alt='TBA' />
        <img className={classes['image-mobile']} src={homeMobile} alt='TBA' />
        <div className={`${classes.textContainer} row`}>
          <div className={`col-12 col-md-8 col-lg-6 ${classes.text}`}>
            <h1>Vi bygger gemenskap, kompetens och lösningar som håller</h1>
          </div>
        </div>
      </div>
      <Section
        className={classes.part2}
        backgroundColor='black'
        textColor='white'
        rounded='top'
      >
        <div className='row center'>
          <div className={`col-12 col-md-8 mb-3`}>
            <p className='large-text mb-1'>
              Vi gör duktiga utvecklingsintensiva team ännu bättre.
            </p>
            <p className='text'>
              Genom gedigen erfarenhet och stort tekniskt kunnande hjälper vi
              till med utveckling och kvalitetssäkring och bidrar därmed till
              att skapa grym mjukvara.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='d-none col-md-2'></div>
          <div className={`col-12 col-md-6 mb-3 mb-md-0 text ${classes.text}`}>
            <p className='text mb-3'>
              Vi har varit en del av skapandet av det nya digitala samhället
              sedan 2006. Vårt team består av dedikerade och erfarna konsulter
              som med passion för teknik drivs av att skapa förändring genom
              teknologi.
            </p>
          </div>
          <div className={`d-none col-md-2 ${classes.arrow}`}>
            <img src={arrow} alt='A yellow arrow' />
          </div>
        </div>

        <div className='row'>
          <div className='d-none col-md-2'></div>

          <div className='col-12 col-md-8 col-lg-6'>
            <p className='text mb-3'>
              Vi bygger inte bara projekt, utan även relationer. Vill du prata
              med oss om hur vi blir bättre tillsammans?
            </p>
            <div className={`${classes.link}`}>
              <LinkButton to='/contact' color='white'>
                Kontakta oss
              </LinkButton>
            </div>
          </div>
        </div>
      </Section>
      <div className={classes['background-image']}>
        <div className={`row center ${classes['image-text-part']}`}>
          <div className='col-12 col-md-8 col-lg-6'>
            <p className='large-text mb-3'>
              Kompetens genom kontinuerlig utveckling.
            </p>
            <p className='text mb-3'>
              Vi förstår att kompetens är en kontinuerlig process, inte ett
              slutmål. Vi investerar i vår egen utveckling och strävar ständigt
              efter att lära oss nya saker. Vi delar med oss av vår kunskap, för
              vi tror på att gemensam kunskap höjer oss alla och främjar
              kreativitet.
            </p>
            <div className={classes.link}>
              <LinkButton to='/about#our-values' color='black'>
                LÄS MER OM VAD VI TROR PÅ
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
      <Section backgroundColor='yellow' textColor='black'>
        <div className='row mb-6'>
          <div className='d-none col-md-2'></div>
          <div className='col-12 col-md-8 mb-md-0'>
            <p className='large-text mb-3'>
              Mjukvaruutveckling och mjukvarutestning är vår spetskompetens
            </p>
            <p className='text'>
              Vi ser det som olika sidor av samma mynt. Båda sidor är avgörande
              för framställningen av pålitlig och effektiv programvara: med
              samma mål men från olika perspektiv och med olika syften.
            </p>
          </div>
        </div>
        <div className='row center'>
          <div className='col'>
            <LinkButton to='/about' color='black'>
              LÄS MER OM VAD VI KAN
            </LinkButton>
          </div>
        </div>
      </Section>
      <div className={classes.part5}>
        <div className={classes.component}>
          <ImageText
            title='LEDIGA UPPDRAG'
            image={mountainImg}
            overlayColor='yellow'
            link={{
              linkColor: 'black',
              linkTo: '/assignments',
              linkText: 'Se våra lediga uppdrag',
            }}
          >
            {openAssignmentsText}
          </ImageText>
        </div>
        <div className={classes.component}>
          <ImageText
            title='JOBBA HOS OSS'
            image={handsImg}
            overlayColor='yellow'
            link={{
              linkColor: 'black',
              linkTo: '/career',
              linkText: 'Se hur det är att jobba hos oss',
            }}
          >
            {workWithUsText}
          </ImageText>
        </div>
        <div className={classes.component}>
          <ImageText
            title='KURSER'
            image={vrWomanImg}
            overlayColor='yellow'
            link={{
              linkColor: 'black',
              linkTo: '/courses',
              linkText: 'Se vårt kursutbud',
            }}
          >
            {coursesText}
          </ImageText>
        </div>
      </div>
    </>
  );
}
