import Footer from '../components/Footer';
import NavBar from '../components/NavBar';

import { Outlet } from 'react-router-dom';
import ScrollComponent from '../components/ScrollComponent';

export default function Root() {
  return (
    <>
      <ScrollComponent />
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
}
