import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * If path contains a hash-path, the hash counts as an element-id and will be searched and scrolled to. With the bottom of the selected element aligned with the bottom of the page
 * If path does not contain a hash, the component will scroll the window to top
 * @returns
 */
export default function ScrollComponent() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView(false);
      }
    }
  }, [pathname, hash, key]);

  return null;
}
