import classes from './Button.module.scss';

const Button: React.FC<{
  children: React.ReactNode;
  color: 'black' | 'white';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: () => void;
}> = props => {
  return (
    <button
      onClick={props.onClick}
      className={`${classes.button} ${classes[`c-${props.color}`]} ${
        props.disabled ? classes.disabled : ''
      } button-text`}
      type={props.type}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default Button;
