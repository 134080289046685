import classes from './Hero.module.scss';
import ImageOverlay from './ImageOverlay';

const Hero: React.FC<{
  image: string;
  imageAlt: string;
  headerText: string;
  headerColor?: 'white' | 'black' | 'yellow';
  text?: string;
  textPos: 'left' | 'right';
  textColor: 'white' | 'black';
  imageOverlay?: string;
}> = props => {
  return (
    <div className={classes.banner}>
      <img src={props.image} alt={props.imageAlt} />
      {props.imageOverlay && (
        <div
          className={classes.overlay}
          style={{ backgroundColor: props.imageOverlay }}
        ></div>
      )}
      <ImageOverlay textColor={props.textColor} textPos={props.textPos}>
        <h3
          className={`mb-3 ${
            props.headerColor ? classes[`h-c-${props.headerColor}`] : ''
          }`}
        >
          {props.headerText}
        </h3>
        {props.text && <div className='large-text'>{props.text}</div>}
      </ImageOverlay>
    </div>
  );
};

export default Hero;
