import Section from './Section';
import classes from './BasicCourseForm.module.scss';
import FormInput from './FormInput';
import { useReducer, useRef, useState } from 'react';
import { emailInitialState, emailReducer } from '../reducers/email-reducer';
import {
  notEmptyInitialState,
  notEmptyStringReducer,
} from '../reducers/not-empty-string-reducer';
import {
  notEmptyFileReducer,
  notEmptyFileInitialState,
} from '../reducers/not-empty-file-reducer';
import Button from './Button';
import FormGdprCheckbox from './FormGdprCheckbox';
import {
  checkboxInitialState,
  checkboxReducer,
} from '../reducers/checkbox-reducer';
import FileInput from './FileInput';

const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL ||
  'https://europe-west1-admin-publicering.cloudfunctions.net';

const AssignmentForm: React.FC<{}> = props => {
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const availableFromDateRef = useRef<HTMLInputElement>(null);
  const availableToDateRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);
  const rolesRef = useRef<HTMLInputElement>(null);
  const resumeRef = useRef<HTMLLabelElement>(null);
  const policyRef = useRef<HTMLInputElement>(null);

  const [emailState, dispatchEmail] = useReducer(
    emailReducer,
    emailInitialState
  );

  const [firstNameState, dispatchFirstName] = useReducer(
    notEmptyStringReducer,
    notEmptyInitialState
  );

  const [lastNameState, dispatchLastName] = useReducer(
    notEmptyStringReducer,
    notEmptyInitialState
  );

  const [phoneState, dispatchPhone] = useReducer(
    notEmptyStringReducer,
    notEmptyInitialState
  );

  const [resumeState, dispatchResume] = useReducer(
    notEmptyFileReducer,
    notEmptyFileInitialState
  );

  const [acceptsPolicyState, dispatchAcceptsPolicy] = useReducer(
    checkboxReducer,
    checkboxInitialState
  );

  const [availableFromDate, setAvailableFromDate] = useState('');
  const [availableToDate, setAvailableToDate] = useState('');
  const [location, setLocation] = useState('');
  const [roles, setRoles] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [submitError, setSubmitError] = useState('');

  async function submitHandler(event: any) {
    event.preventDefault();
    if (!firstNameState.isValid) {
      firstNameRef.current!.focus();
      return;
    }
    if (!emailState.isValid) {
      emailRef.current!.focus();
      return;
    }
    if (!phoneState.isValid) {
      phoneRef.current!.focus();
      return;
    }
    if (!acceptsPolicyState.isValid) {
      policyRef.current!.focus();
      return;
    }
    if (!resumeState.isValid || resumeState.value === null) {
      resumeState.isValid = false;
      dispatchResume({ val: null, type: 'INPUT' });
      return;
    }

    const formData = new FormData();
    formData.append(resumeState.value.name, resumeState.value);

    const requestData = {
      roles: roles,
      location: location,
      email: emailState.value,
      lastName: lastNameState.value,
      phoneNumber: phoneState.value,
      firstName: firstNameState.value,
      availableFromDate: availableFromDate,
      availableToDate: availableToDate,
    };

    formData.append('form', JSON.stringify(requestData));

    try {
      setIsSubmitting(true);
      setSubmitError('');
      setSubmitSuccessful(false);
      const response = await fetch(BASE_API_URL + '/createUserSubcontractor', {
        method: 'POST',
        headers: {},
        body: formData,
      });

      if (!response) {
        setSubmitError('Något gick fel, kunde inte skicka in anmälan.');
        return;
      }

      const responseJson = await response.json();
      const result = responseJson;

      if (result.status) {
        setSubmitSuccessful(true);
        dispatchFirstName({ type: 'RESET' });
        dispatchLastName({ type: 'RESET' });
        dispatchEmail({ type: 'RESET' });
        dispatchPhone({ type: 'RESET' });
        dispatchResume({ type: 'RESET' });
        dispatchAcceptsPolicy({ type: 'RESET' });

        setLocation('');
        setAvailableFromDate('');
        setAvailableToDate('');
        setRoles('');
      } else {
        if (result.errorCode === 1) {
          setSubmitError(result.error);
        } else {
          setSubmitError('Något gick fel, kunde inte skicka in anmälan.');
        }
      }
    } catch (e) {
      setSubmitError('Något gick fel, kunde inte skicka in anmälan.');
    } finally {
      setIsSubmitting(false);
    }
  }

  async function setResume(event: any) {
    const files = event.target?.files;

    if (!files) return;
    const file = files[0];
    console.log('file', file);
    dispatchResume({ val: file, type: 'INPUT' });
  }

  return (
    <Section backgroundColor='black' textColor='white'>
      <div className='row mb-6'>
        <div className='col-12 col-md-10'>
          <h3 className={`${classes.header} mb-3`}>Registrera dig</h3>
          <p className='text'>
            Registrera ditt CV
            <br /> Fyll i dina kontaktuppgifter i formuläret, så håller vi utkik
            efter uppdrag för dig.
          </p>
        </div>
      </div>
      <form onSubmit={submitHandler}>
        <div className={`${classes['form-row']} row mb-6`}>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={firstNameRef}
              id='first-name'
              name='Namn'
              type='text'
              value={firstNameState.value}
              errorMessage={
                firstNameState.isValid === false
                  ? 'Fyll i ditt namn.'
                  : undefined
              }
              onBlur={() => dispatchFirstName({ type: 'BLUR' })}
              onChange={e =>
                dispatchFirstName({ type: 'INPUT', val: e.currentTarget.value })
              }
              required
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={lastNameRef}
              id='last-name'
              name='Efternamn'
              type='text'
              value={lastNameState.value}
              errorMessage={
                lastNameState.isValid === false
                  ? 'Fyll i ditt namn.'
                  : undefined
              }
              onBlur={() => dispatchLastName({ type: 'BLUR' })}
              onChange={e =>
                dispatchLastName({ type: 'INPUT', val: e.currentTarget.value })
              }
              required
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={emailRef}
              id='email'
              name='E-postadress'
              type='text'
              value={emailState.value}
              errorMessage={
                emailState.isValid === false
                  ? 'Fyll i en korrekt emailadress.'
                  : undefined
              }
              onBlur={() => dispatchEmail({ type: 'BLUR' })}
              onChange={e =>
                dispatchEmail({ type: 'INPUT', val: e.currentTarget.value })
              }
              required
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={phoneRef}
              id='phone'
              name='Telefonnummer'
              type='text'
              value={phoneState.value}
              errorMessage={
                phoneState.isValid === false
                  ? 'Fyll i ett telefonnummer.'
                  : undefined
              }
              onBlur={() => dispatchPhone({ type: 'BLUR' })}
              onChange={e =>
                dispatchPhone({ type: 'INPUT', val: e.currentTarget.value })
              }
              required={false}
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={rolesRef}
              id='roles'
              name='Jag är intresserad av de här rollerna'
              type='text'
              value={roles}
              errorMessage={undefined}
              onChange={e => setRoles(e.currentTarget.value)}
              required={false}
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={locationRef}
              id='location'
              name='Jag kan jobba i'
              type='text'
              value={location}
              errorMessage={undefined}
              onChange={e => setLocation(e.currentTarget.value)}
              required={false}
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={availableFromDateRef}
              id='available-from'
              name='Tillgänglig från'
              type='date'
              value={availableFromDate}
              errorMessage={undefined}
              onChange={e => setAvailableFromDate(e.currentTarget.value)}
              required={false}
            />
          </div>
          <div className='col-12 col-md-6'>
            <FormInput
              ref={availableToDateRef}
              id='available-to'
              name='Tillgänglig till'
              type='date'
              value={availableToDate}
              errorMessage={undefined}
              onChange={e => setAvailableToDate(e.currentTarget.value)}
              required={false}
            />
          </div>
          <div className='col-12 col-md-6'>
            <FileInput
              ref={resumeRef}
              id='resume'
              name='CV'
              type='file'
              value={resumeState.value}
              emptyMessage='Ladda upp cv'
              acceptedFileTypes='.pdf,.doc,.docx'
              onBlur={() => dispatchResume({ type: 'BLUR' })}
              errorMessage={
                resumeState.isValid === false ? 'Ladda upp ett cv.' : undefined
              }
              onChange={setResume}
              required
            />
          </div>
        </div>
        <div className={`row mb-6 ${classes['checkbox-row']}`}>
          <div className='col-11 col-sm col-md col-lg'>
            <FormGdprCheckbox
              id='gdpr-checkbox'
              ref={policyRef}
              isValid={acceptsPolicyState.isValid}
              value={acceptsPolicyState.value}
              onBlur={() => dispatchAcceptsPolicy({ type: 'BLUR' })}
              onChange={e =>
                dispatchAcceptsPolicy({
                  type: 'INPUT',
                  val: e.currentTarget.checked,
                })
              }
            />
          </div>
        </div>
        <div className={`row ${classes['button-row']}`}>
          <div className='col'>
            <Button color='white' type='submit' disabled={isSubmitting}>
              {isSubmitting ? 'Skickar...' : 'Skicka'}
            </Button>
          </div>
        </div>
        {submitError && (
          <div className={`row ${classes['submit-error-row']}`}>
            <div className='col'>
              <p>{submitError}</p>
            </div>
          </div>
        )}
        {submitSuccessful && (
          <div className={`row ${classes['submit-successful-row']}`}>
            <div className='col'>
              <p>Anmälan skickad!</p>
            </div>
          </div>
        )}
      </form>
    </Section>
  );
};

export default AssignmentForm;
