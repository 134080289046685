import Hero from '../components/Hero';
import classes from './Contact.module.scss';
import Section from '../components/Section';
import EmployeeCard from '../components/EmployeeCard';

import peopleImg from '../assets/images/Kontaktsida_Samlad.webp';
import yellowArrow from '../assets/images/yellow-arrow.webp';
import { employees } from '../data/Employees';
import SeoComponent from '../components/HeadComponent';

const heroHeader = 'Välkommen att kontakta oss';

export default function ContactPage() {
  return (
    <>
      <SeoComponent
        description='Kontakta oss på Iceberry – ett ledande IT-konsultföretag. Maila eller ring oss direkt för att diskutera dina behov och frågor - vi är här för att hjälpa dig.'
        keywords='Kontakta Iceberry,IT-konsultföretag kontakt,Kontaktuppgifter Iceberry,Kontakt för samarbete,Bli partner med Iceberry,Skicka förfrågan IT,Frågor om IT-uppdrag,Boka möte med IT-konsult,Karriärfrågor kontakt'
      />

      <Hero
        image={peopleImg}
        imageAlt='A picture of some of the employees at Iceberry together'
        headerText={heroHeader}
        textColor='white'
        textPos='left'
        imageOverlay='#00000050'
      />
      <Section backgroundColor='black' textColor='white'>
        <div className={`row ${classes.row}`}>
          <div
            className={`col-12 col-md-6 mb-6 mb-md-0 ${classes['first-col']}`}
          >
            <p className='large-text'>
              Vi finns i Stockholm och Göteborg. Kom gärna förbi och ta en
              kaffe!
            </p>
            <img
              src={yellowArrow}
              alt='A decorative yellow arrow'
              className={classes.arrow}
            />
          </div>
          <div className='col-12 col-md-6'>
            <p className='text mb-3'>
              Telefon: +46 (0)10-660 06 30
              <br />
              Mail: hello@iceberry.se
            </p>
            <p className='text mb-3'>
              Fleminggatan 14,
              <br />
              112 26 Stockholm, Sverige
            </p>
            <p className='text'>
              Lindholmspiren 7A,
              <br />
              417 56 Göteborg, Sverige
            </p>
          </div>
        </div>
      </Section>
      <Section backgroundColor='yellow' textColor='black'>
        <div className='row mb-6'>
          <div className='col'>
            <h4>Eller kontakta någon av oss direkt</h4>
          </div>
        </div>
        <div className={`row ${classes['employee-row']}`}>
          {employees.map((e, i) => (
            <EmployeeCard
              key={i}
              image={e.image}
              name={e.name}
              title={e.title}
              email={e.email}
            />
          ))}
        </div>
      </Section>
    </>
  );
}
