import React from 'react';
import classes from './FormInput.module.scss';

type Props = {
  id: string;
  name: string;
  type: string;
  value: string | null;
  errorMessage?: string;
  required?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
};

const FormInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <div
      className={`${classes.container} ${
        props.errorMessage ? classes.invalid : ''
      }`}
    >
      <label className='small-text' htmlFor={props.id}>
        {props.name}
        {props.required ? ' *' : ''}
      </label>
      <input
        ref={ref}
        className='text'
        id={props.id}
        type={props.type}
        value={props.value || ''}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
      {props.errorMessage && (
        <p className={classes['error-message']}>{props.errorMessage}</p>
      )}
    </div>
  );
});

export default FormInput;
