import SwedishIntegrity from '../components/Integrity/SwedishIntegrity';
import EnglishIntegrity from '../components/Integrity/EnglishIntegrity';

export default function IntegrityPage(props: { language: 'en' | 'se' }) {
  if (props.language === 'se') {
    return <SwedishIntegrity />;
  } else {
    return <EnglishIntegrity />;
  }
}
