import { useEffect, useState } from 'react';

import wavingImg from '../assets/images/Vinka.jpg';

import Hero from '../components/Hero';
import { useParams } from 'react-router-dom';

const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL ||
  'https://europe-west1-admin-publicering.cloudfunctions.net';

export default function UnsubscribePage() {
  const [isLoading, setIsLoading] = useState(true);

  let { assignmentSubscriberId } = useParams();

  useEffect(() => {
    fetch(BASE_API_URL + '/unsubscribeToRoles', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: { id: assignmentSubscriberId } }),
    })
      .then(response => response.json())
      .then(() => setIsLoading(false))
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Hero
        image={wavingImg}
        imageAlt='A man waving to his shadow'
        headerText={isLoading ? 'Laddar' : 'Prenumeration avslutad'}
        text={isLoading ? '' : 'Du kan när som helst prenumerera igen'}
        textPos='left'
        textColor='white'
        imageOverlay='#00000060'
      />
    </>
  );
}
