import classes from './EmployeeCard.module.scss';

import planeIcon from '../assets/icons/plane.svg';

const EmployeeCard: React.FC<{
  image: string;
  name: string;
  title: string;
  email?: string;
}> = props => {
  return (
    <div className='col-12 col-md-6 col-lg-4'>
      <img
        src={props.image}
        alt={`Portrait of ${props.name}`}
        className={`mb-3 ${classes.image}`}
      />
      <p className={`large-text mb-1 ${classes.name}`}>{props.name}</p>
      <p className='text'>{props.title}</p>
      {props.email && (
        <div className={classes['email-container']}>
          <img src={planeIcon} className='icon' alt='A paper plane' />
          <a href={`mailto:${props.email}`} className='small-text'>{props.email}</a>
        </div>
      )}
    </div>
  );
};

export default EmployeeCard;
