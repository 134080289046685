import classes from './EmployeeCarouselSlide.module.scss';
import quoteIcon from '../../assets/icons/quote.svg';
import { motion } from 'framer-motion';

const variants = {
  initialLeft: {
    left: '100vw',
    transition: { duration: 0.5 },
  },
  initialRight: {
    right: '100vw',
    transition: { duration: 0.5 },
  },
  normal: {
    left: 0,
    right: 0,
    opacity: 1,
    transition: { duration: 0.5 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2 },
  },
};

const EmployeeCarouselSlideDesktop: React.FC<{
  key: string;
  text: string;
  name: string;
  image: string;
  imageAlt: string;
  imagePos: 'left' | 'right';
}> = props => {
  return (
    <motion.div
      key={props.key}
      className={`row d-none d-md-flex ${classes.row} ${classes.desktop} ${
        classes[`img-pos-${props.imagePos}`]
      }`}
      variants={variants}
      initial={props.imagePos === 'left' ? 'initialLeft' : 'initialRight'}
      animate='normal'
      exit='exit'
      transition={{ duration: 0.5 }}
    >
      <div className={`col-md-8 ${classes['text-col']}`}>
        <div
          className={`${classes.background} ${
            classes[`justify-pos-${props.imagePos}`]
          }`}
        ></div>
        <div className={classes.text}>
          <p className='quote mb-3'>{props.text}</p>
          <div className={classes.quote}>
            <img
              src={quoteIcon}
              alt='quotation marks'
              className={`icon black`}
            />
            <h5>{props.name}</h5>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <img src={props.image} alt={props.imageAlt} className={classes.image} />
      </div>
    </motion.div>
  );
};

const EmployeeCarouselSlideMobile: React.FC<{
  key: string;
  text: string;
  name: string;
  image: string;
  imageAlt: string;
  imagePos: 'left' | 'right';
}> = props => {
  return (
    <motion.div
      key={props.key}
      className={`row d-md-none ${classes.row} ${classes.mobile} ${
        classes[`img-pos-${props.imagePos}`]
      }`}
      variants={variants}
      initial={props.imagePos === 'left' ? 'initialLeft' : 'initialRight'}
      animate='normal'
      exit='exit'
      transition={{ duration: 0.5 }}
    >
      <div className='col-1'></div>
      <div
        className={`col-6 ${classes['image-col']} ${
          classes[`img-pos-${props.imagePos}`]
        }`}
      >
        <img src={props.image} alt={props.imageAlt} className={classes.image} />
      </div>
      <div className='col-1'></div>
      <div className={`col-12 ${classes['text-container']}`}>
        <div
          className={`${classes.background} ${
            classes[`img-pos-${props.imagePos}`]
          }`}
        ></div>
        <div className={classes.text}>
          <p className='quote mb-3'>{props.text}</p>
          <div className={classes.quote}>
            <img
              src={quoteIcon}
              alt='quotation marks'
              className={`icon black`}
            />
            <h5>{props.name}</h5>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const EmployeeCarouselSlide: React.FC<{
  key: string;
  text: string;
  name: string;
  image: string;
  imageAlt: string;
  imagePos: 'left' | 'right';
}> = props => {
  return (
    <>
      <EmployeeCarouselSlideDesktop {...props} />
      <EmployeeCarouselSlideMobile {...props} />
    </>
  );
};

export default EmployeeCarouselSlide;
