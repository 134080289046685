import React from 'react';
import classes from './FormGdprCheckbox.module.scss';
import { Link } from 'react-router-dom';

type Props = {
  id: string;
  isValid?: boolean | null;
  value?: boolean;
  onBlur?: () => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
};

const FormGdprCheckbox = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    return (
      <label
        className={`small-text ${classes.container} ${
          props.isValid === false ? classes.error : ''
        }`}
      >
        <input
          ref={ref}
          id={props.id}
          type='checkbox'
          checked={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
        <span className={classes.checkmark}></span>
        Jag godkänner att mina personuppgifter hanteras i enlighet med gällande{' '}
        <Link target='_blank' to='/integrity/se'>
          integritetspolicy
        </Link>
        .
      </label>
    );
  }
);

export default FormGdprCheckbox;
