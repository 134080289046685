import { useEffect } from 'react';

import bannerImage from '../assets/images/led/Startsida.jpg';
import logoIcon from '../assets/images/led/logo.svg';
import logoWithName from '../assets/images/led/logo_with_name.svg';

import carousel1Img1 from '../assets/images/led/carousel-1/daniel-fönster.jpg';
import carousel1Img2 from '../assets/images/led/carousel-1/maria-glad1.jpg';
import carousel1Img3 from '../assets/images/led/carousel-1/Per-glad.jpg';
import carousel1Img4 from '../assets/images/led/carousel-1/Sigalit-glad-föreläser.jpg';

import carousel2Img1 from '../assets/images/led/carousel-2/gif-johan-ljungberg-iceberry.gif';
import carousel2Img2 from '../assets/images/led/carousel-2/IMG_1901 - stor.jpeg';
import carousel2Img3 from '../assets/images/led/carousel-2/postery-media.jpg';

import qrCodeImage from '../assets/images/led/qr-code.png';

import classes from './LedScreenPage.module.scss';

export default function LedScreenPage() {
  useEffect(() => {
    const carousel1 = document.getElementById('carousel-1');
    const carousel1Slides = carousel1?.querySelectorAll('.carousel-item');
    const carousel2 = document.getElementById('carousel-2');
    const carousel2Slides = carousel2?.querySelectorAll('.carousel-item');

    if (
      !carousel1Slides ||
      carousel1Slides.length < 1 ||
      !carousel2Slides ||
      carousel2Slides.length < 1
    )
      return;

    function addActive(slide: Element) {
      slide.classList.add(classes.active);
    }

    function removeActive(slide: Element) {
      slide.classList.remove(classes.active);
    }

    addActive(carousel1Slides![0]);
    addActive(carousel2Slides![0]);

    let timeout1: ReturnType<typeof setTimeout>;
    let timeout2: ReturnType<typeof setTimeout>;
    const handleSlide = (carouselNumber: number) => {
      let slides;

      if (carouselNumber === 1) {
        slides = carousel1Slides;
      } else {
        slides = carousel2Slides;
      }

      for (let i = 0; i < slides!.length; i++) {
        if (i + 1 === slides!.length) {
          addActive(slides![0]);
          timeout1 = setTimeout(removeActive, 350, slides![i]);
          break;
        }
        if (slides![i].classList.contains(classes.active)) {
          timeout2 = setTimeout(removeActive, 350, slides![i]);
          addActive(slides![i + 1]);
          break;
        }
      }
    };

    const interval = setInterval(function () {
      handleSlide(1);
      setTimeout(() => {
        handleSlide(2);
      }, 500);
    }, 3000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);

  return (
    <>
      <main className={classes.container}>
        <img
          className={classes['background-image']}
          src={bannerImage}
          alt='banner'
        />
        <img className={classes['logo-image']} src={logoIcon} alt='Logo' />
        <img
          className={classes['logo-name-image']}
          src={logoWithName}
          alt='Logo with company name'
        />

        <h1>Välkommen till oss</h1>

        <p className={classes['about-us']}>
          Vi är ett digitalt hem för gemenskap och kvalitet
        </p>

        <div className={classes['carousel-container']}>
          <div id='carousel-1' className={classes.carousel}>
            <div className={`${classes['carousel-item']} carousel-item`}>
              <div
                className={classes['slide-image']}
                style={{
                  backgroundImage: `url(
                    '${carousel1Img1}'
                  )`,
                }}
              ></div>
            </div>
            <div className={`${classes['carousel-item']} carousel-item`}>
              <div
                className={classes['slide-image']}
                style={{
                  backgroundImage: `url(
                    '${carousel1Img2}'
                  )`,
                }}
              ></div>
            </div>
            <div className={`${classes['carousel-item']} carousel-item`}>
              <div
                className={classes['slide-image']}
                style={{
                  backgroundImage: `url(
                    '${carousel1Img3}'
                  )`,
                }}
              ></div>
            </div>
            <div className={`${classes['carousel-item']} carousel-item`}>
              <div
                className={classes['slide-image']}
                style={{
                  backgroundImage: `url(
                    '${carousel1Img4}'
                  )`,
                }}
              ></div>
            </div>
          </div>
          <div id='carousel-2' className={classes.carousel}>
            <div className={`${classes['carousel-item']} carousel-item`}>
              <div
                className={classes['slide-image']}
                style={{
                  backgroundImage: `url(
                    '${carousel2Img1}'
                  )`,
                }}
              ></div>
            </div>
            <div className={`${classes['carousel-item']} carousel-item`}>
              <div
                className={classes['slide-image']}
                style={{
                  backgroundImage: `url(
                    '${carousel2Img2}'
                  )`,
                }}
              ></div>
            </div>
            <div className={`${classes['carousel-item']} carousel-item`}>
              <div
                className={classes['slide-image']}
                style={{
                  backgroundImage: `url(
                    '${carousel2Img3}'
                  )`,
                }}
              ></div>
            </div>
          </div>
        </div>

        <p className={classes['about-us-2']}>
          Vi är en brokig skara individer inom utveckling, test och
          kvalitetssäkring.
        </p>

        <img className={classes['qr-code']} src={qrCodeImage} alt='QR-code' />
      </main>
    </>
  );
}
