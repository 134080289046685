import React from 'react';
// import formInputClasses from './FormInput.module.scss';
import classes from './FileInput.module.scss';
import cloudUploadImage from '../assets/icons/cloud_upload.svg';

type Props = {
  id: string;
  name: string;
  type: string;
  /**
   * A comma-separated string with accepted file types
   */
  acceptedFileTypes: string;
  value: File | null;
  emptyMessage: string;
  errorMessage?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
};

const FileInput = React.forwardRef<HTMLLabelElement, Props>((props, ref) => {
  return (
    <div
      className={`${classes.container} ${
        props.errorMessage ? classes.invalid : ''
      }`}
    >
      <label className='small-text' htmlFor={props.id}>
        {props.name}
        {props.required ? ' *' : ''}
      </label>
      <label
        tabIndex={0}
        ref={ref}
        className={'text ' + classes['file-input-label']}
        htmlFor={props.id}
        onBlur={props.onBlur}
      >
        <img src={cloudUploadImage} alt='Upload file logo' />
        <span>{props.value?.name || props.emptyMessage}</span>
        <input
          className={classes['file-input']}
          accept={props.acceptedFileTypes}
          id={props.id}
          type={props.type}
          onChange={props.onChange}
        />
      </label>
      {props.errorMessage && (
        <p className={classes['error-message']}>{props.errorMessage}</p>
      )}
    </div>
  );
});

export default FileInput;
