import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import classes from './AnimatedLink.module.scss';
import { useState } from 'react';

const variants = {
  normal: {
    width: 0,
  },
  hover: {
    width: '100%',
  },
};

const AnimatedLink: React.FC<{
  children: React.ReactNode;
  to: string;
}> = props => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link to={props.to} className={classes.link}>
      <div className={classes.container}>
        <motion.div
          onHoverStart={() => setIsHovered(true)}
          onHoverEnd={() => setIsHovered(false)}
        >
          {props.children}
        </motion.div>
        <AnimatePresence>
          {isHovered && (
            <motion.div
              className={classes.line}
              variants={variants}
              initial='normal'
              animate='hover'
              exit='normal'
            ></motion.div>
          )}
        </AnimatePresence>
      </div>
    </Link>
  );
};

export default AnimatedLink;
