import { useEffect, useState } from 'react';
import Section from '../Section';
import EmployeeCarouselSlide from './EmployeeCarouselSlide';

import classes from './EmployeeCarousel.module.scss';

import jlPortrait from '../../assets/images/employees/career-johan.webp';
import unPortrait from '../../assets/images/employees/career-ulrica.webp';
import pkPortrait from '../../assets/images/employees/career-per.webp';

import EmployeeCarouselSlideIndicators from './EmployeeCarouselSlideIndicators';

const tempQuote1 =
  'Man har en fördel som konsult, eftersom det går att anpassa uppdragen utifrån vad man är intresserad av och vart man vill ta vägen. Det jag uppskattar med Iceberry är att vi är ett litet tight gäng där alla känner alla.';

const tempQuote2 =
  'Jag sökte mig till Iceberry eftersom det finns en värme, en genuinitet och en äkthet. Det känns som att man väljer att rekrytera människor som inte bara kunskapsmässigt uppfyller kraven, utan att det även finns något annat som jag värdesätter väldigt mycket';

const tempQuote3 =
  'Det är viktigt med kompetensutveckling eftersom vi är konsulter. Det vi säljer är ju vår kompetens, så vi måste hålla oss à jour med vad som händer för att kunna leverera hos kund, eftersom våra kunder ligger i framkant. Vi bygger också ett starkare team genom att ha en kultur att lära oss av varandra och lära känna varandra bättre.';

export default function EmployeeCarousel() {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const callback = setInterval(() => {
      setSlideIndex(prevState => (prevState < 2 ? prevState + 1 : 0));
    }, 15000);

    return () => {
      clearInterval(callback);
    };
  }, [slideIndex]);

  function setSlide(index: number) {
    setSlideIndex(index);
  }

  const slide1 = (
    <EmployeeCarouselSlide
      key='1'
      text={tempQuote1}
      image={jlPortrait}
      imageAlt='Picture of Johan Ljungberg'
      imagePos='right'
      name='Johan Ljungberg'
    />
  );
  const slide2 = (
    <EmployeeCarouselSlide
      key='2'
      text={tempQuote2}
      image={unPortrait}
      imageAlt='Picture of Ulrica Nyberg'
      imagePos='left'
      name='Ulrica Nyberg'
    />
  );
  const slide3 = (
    <EmployeeCarouselSlide
      key='3'
      text={tempQuote3}
      image={pkPortrait}
      imageAlt='Picture of Per Klingnäs'
      imagePos='left'
      name='Per Klingnäs'
    />
  );

  return (
    <Section
      backgroundColor='light-varm'
      textColor='black'
      className={classes.section}
    >
      <div className='row'>
        <div className='col mb-6'>
          <h3>Möt några av oss på Iceberry</h3>
        </div>
      </div>
      {slideIndex === 0 && slide1}
      {slideIndex === 1 && slide2}
      {slideIndex === 2 && slide3}
      <EmployeeCarouselSlideIndicators
        activeSlideIndex={slideIndex}
        setSlide={setSlide}
      />
    </Section>
  );
}
